import React, { useEffect, useState } from 'react'
import EditUserSideBar from '../Components/Sidebar/EditUserSideBar'
import apiServices from '../services/apiServices'
import { parse } from '@fortawesome/fontawesome-svg-core'

const User = () => {
    const [getAllUserData, setAllUserData] = useState([])
    const [filterData, setFilterData] = useState("")
    const [UserHeading, setUserHeading] = useState("");
    const storageData = JSON.parse(localStorage.getItem("appAllData"))
    const handleSelectFilterData = (e) => {
        setFilterData(e.target.value)
    }
    const isRetantionPage = window.location.pathname === "/retantion";
    // console.log(window.location.pathname, isRetantionPage)
    const handleUserDownloadData = async () => {
        console.log("handleUserDownloadData...")
        const res = await apiServices.getUserDownload(storageData.addApp.package_name, filterData, null, isRetantionPage)
        console.log("resCount", res)
        setAllUserData(res?.userDownloadData)
    }
    useEffect(() => {
        handleUserDownloadData()
    }, [filterData, isRetantionPage])

    return (
        <>
            {localStorage.getItem("token")
                &&
                <div className="col-lg-2" style={{ height: "100vh" }}>
                    <EditUserSideBar />
                </div>
            }
            <div className={`${localStorage.getItem("token") ? "col-lg-10 pe-5" : "col-lg-12 pe-5"}`}>
                <h3 className='my-4'>{isRetantionPage ? 'Retention Users' : 'Users'}</h3>
                <div>
                    <select className="w-25 form-select" aria-label="Default select example" onChange={handleSelectFilterData}>
                        <option selected>Open this select menu</option>
                        <option value="today">Today</option>
                        <option value="yesterday">Yesterday</option>
                    </select>
                </div>

                <div className='mt-4'>

                    <button type="button" class="btn btn-primary me-2">Total count:{getAllUserData.totalUser}</button>
                    <button type="button" class="btn btn-secondary me-2">Orgnicount: {getAllUserData.organicUserCount}</button>
                    <button type="button" class="btn btn-success">MarketingCount: {getAllUserData.marketingUserCount}</button>

                </div>

                <div className='mt-4' style={{ overflowX: "auto" }}>
                    <table className="table table-striped table-bordered">

                        <thead>
                            <tr>
                                <th scope="col">package</th>
                                <th scope="col">as</th>
                                <th scope="col">asname</th>
                                <th scope="col">city</th>
                                <th scope="col">continent</th>
                                <th scope="col" >country</th>
                                <th scope="col" >countryCode</th>
                                <th scope="col" >hosting</th>
                                <th scope="col">isp</th>
                                <th scope="col">mobile</th>
                                <th scope="col">org</th>
                                <th scope="col">proxy</th>
                                <th scope="col">query</th>
                                <th scope="col">regionName</th>
                                <th scope="col">installerinfo</th>
                                <th scope="col">installerurl</th>

                            </tr>
                        </thead>
                        <tbody style={{ display: getAllUserData.length < 0 ? "none" : "" }}>
                            {getAllUserData?.DownloadUser?.map((item, index) => (
                                <tr key={index}>
                                    <td>{item.userData.package || "-"}</td>
                                    <td>{item.userData.as || "-"}</td>
                                    <td>{item.userData.asname || "-"}</td>
                                    <td>{item.userData.city || "-"}</td>
                                    <td>{item.userData.continent || "-"}</td>
                                    <td>{item.userData.country || "-"}</td>
                                    <td>{item.userData.countryCode || "-"}</td>
                                    <td>{(item.userData.hosting) ? String(item.userData.hosting) : "-"}</td>
                                    <td>{item.userData.isp || "-"}</td>
                                    <td>{(item.userData.mobile) ? String(item.userData.mobile) : "-"}</td>
                                    <td>{item.userData.org || "-"}</td>
                                    <td>{(item.userData.proxy) ? String(item.userData.proxy) : "-"}</td>
                                    <td>{item.userData.query || "-"}</td>
                                    <td>{item.userData.regionName || "-"}</td>
                                    <td>{(item.userData.installerinfo) ? String(item.userData.installerinfo) : "-"}</td>
                                    <td>{item.userData.installerurl || "-"}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div className='text-center' style={{ display: getAllUserData.length > 0 ? "none" : "" }}>
                        <h3>User not available</h3>
                    </div>
                </div>
            </div>
        </>
    )
}

export default User