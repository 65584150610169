import React from 'react'
import Sidebar from '../Components/Sidebar/Sidebar';
import { useState } from 'react';
import AddButton from '../Components/Button/AddButton';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import apiServices from '../services/apiServices';
import { useEffect } from 'react';

const Adx = () => {
    const [adxData, setAdxData] = useState([])
    const [adxName, setAdxName] = useState();
    const [isEditButton, setIsEditButton] = useState(false);
    const [countData, setCountData] = useState(0)
    const [adxId, setAdxID] = useState()


    const handleInputAdx = (e) => {
        setAdxName(e.target.value)
    }

    const handleCreateAdx = async (e) => {
        e.preventDefault();
        if (isEditButton) {
            const bodyData = {
                name: adxName
            }
            const res = await apiServices.updateAdx(bodyData, adxId)
            if (res) {
                setCountData(countData + 1)
            }
        }
        else {

            const bodyData = {
                name: adxName
            }
            const res = await apiServices.createAdxName(bodyData)
            if (res) {
                setCountData(countData + 1)
            }
        }
        // console.log("res+++", res)
    }

    const getAllAdxData = async () => {
        const response = await apiServices.getAllAdxData()
        console.log("responseGetAdx", response)
        setAdxData(response?.data?.getAllAdex)
    }

    useEffect(() => {
        getAllAdxData()
    }, [countData])

    const handleEditAdx = (data, id) => {
        setAdxID(id)
        setAdxName(data?.name)
        setIsEditButton(true)
    }
    const handleDeletAdx = async (id) => {
        const res = await apiServices.deleteAdxData(id)
        if (res) {
            setCountData(countData + 1)
        }

    }


    return (
        <>
            {localStorage.getItem("token")
                &&
                <div className="col-lg-2" style={{ height: "100vh" }}>
                    <Sidebar />
                </div>
            }
            <div className={`${localStorage.getItem("token") ? "col-lg-10" : "col-lg-12"}`}>
                <div className='container'>
                    <h3 className='heading-playstore'>ADX</h3>
                    <div className='row'>
                        <div className='col-lg-6'>
                            <div className="form-group pb-5">
                                <label className='playstore-label' htmlFor="name">Name</label>
                                <div className='mt-2'>
                                    <input
                                        type="text"
                                        name="adx-name"
                                        value={adxName}
                                        className="form-control"
                                        id="adx-name"
                                        placeholder="Enter Name"
                                        onChange={handleInputAdx}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <AddButton onClick={handleCreateAdx}
                        buttonName={"Add"}
                        isEditButton={isEditButton}

                    />
                </div>
                <div className="table-responsive mt-5">
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th style={{ textAlign: "center" }}>Id</th>
                                <th style={{ textAlign: "center" }}>Name</th>
                                <th style={{ textAlign: "center" }}>Action</th>
                            </tr>
                        </thead>
                        <tbody>

                            {adxData?.map((item, index) => (

                                <tr key={index}>
                                    <td style={{ textAlign: "center" }}>{item.a_id}</td>
                                    <td style={{ textAlign: "center" }}>{item.name}</td>
                                    <td style={{ textAlign: "center" }}>
                                        <div className='d-flex justify-content-evenly'>
                                            <span style={{ cursor: "pointer" }} onClick={() => handleEditAdx(item, item._id)} ><FontAwesomeIcon icon={faPen} /></span>
                                            <span style={{ cursor: "pointer" }} onClick={() => handleDeletAdx(item._id)} ><FontAwesomeIcon style={{ color: "red" }} icon={faTrash} /></span>
                                        </div>
                                    </td>
                                </tr>
                            ))}

                        </tbody>
                    </table>
                </div>
            </div>



        </>
    )
}

export default Adx;