import React, { useEffect, useState } from 'react'
import RadioButton from '../Components/RadioButton/RadioButton';
import "../Css/OtherSettingOrganic.css"
import AddButton from '../Components/Button/AddButton';
import EditUserSideBar from '../Components/Sidebar/EditUserSideBar';
import { Redio } from '../Components/Redio/Redio';
import apiServices from '../services/apiServices';
import { useNavigate } from 'react-router';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import Header from '../Components/Header/Header';
import { Button, Form, Modal } from 'react-bootstrap';

const OtherSettingOrganic = () => {
    const navigate = useNavigate()
    const [addBlock, setAddBlock] = useState("");
    const [allAds, setAllAds] = useState("false");
    const [screenNavigationFull, setScreenNavigationFull] = useState("false");
    const [continueScreen, setContinueScreen] = useState("false");
    const [startScreen, setStartScreen] = useState("false");
    const [genderScreen, setGenderScreen] = useState("false");
    const [nextScreen, setNextScreen] = useState("false");
    const [nextInnerScreen, setNextInnerScreen] = useState("false");
    const [connectScreen, setConnectScreen] = useState("false");
    const [startAgainScreen, setStartAgainScreen] = useState("false");
    const [castingFlow, setCastingFlow] = useState("false");
    const [dialogApp, setDialogApp] = useState("false");
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [newFieldName, setNewFieldName] = useState("")
    const [newFieldType, setNewFieldType] = useState("radio")

    const [additionalFields, setAdditionalFields] = useState([])
    console.log("additionalFields::::", additionalFields)
    useEffect(() => { }, [additionalFields])

    const handleRadioSubmit = (e) => {
        e.preventDefault();
    }

    const handleAllAdsRadio = (e) => {
        setAllAds(e.target.value)
        console.log("value", e.target.value)
    }

    const handleScreenNavigation = (e) => {
        setScreenNavigationFull(e.target.value)
    }
    const handleContinueScreen = (e) => {
        setContinueScreen(e.target.value)
    }
    const handleLetsStartScreen = (e) => {
        setStartScreen(e.target.value)
    }
    const handleGenderScreen = (e) => {
        setGenderScreen(e.target.value)
    }
    const handleNextScreen = (e) => {
        setNextScreen(e.target.value)
    }
    const handleNextInnerScreen = (e) => {
        setNextInnerScreen(e.target.value)
    }
    const handleConnectScreen = (e) => {
        setConnectScreen(e.target.value)
    }
    const handleStartScreen = (e) => {
        setStartAgainScreen(e.target.value)
    }
    const handleCastinFlow = (e) => {
        setCastingFlow(e.target.value)
    }
    const handleDialogApp = (e) => {
        setDialogApp(e.target.value)
    }
    const handleAddField = () => {
        console.log("name::::", newFieldName)
        let field = {
            name: newFieldName,
            value: "false",
            type: newFieldType
        }
        if (field.type == "radio-textbox") {
            field.enabled = "true"
            field.value = ""
        }
        setNewFieldName("")
        setNewFieldType("radio")
        setAdditionalFields([...additionalFields, field])
        setShow(false)
    }
    useEffect(() => {
        if (localStorage.getItem("id")) {
            if (localStorage.getItem("appAllData")) {
                const data = JSON.parse(localStorage.getItem("appAllData"))
                if (data.marketing.other_settings != null) {
                    setAddBlock(data?.marketing.other_settings.addBlock)
                    setAllAds(data?.marketing.other_settings.allAds)
                    setScreenNavigationFull(data?.marketing.other_settings.screenNavigationFull || "false")
                    setContinueScreen(data?.marketing.other_settings.continueScreen)
                    setStartScreen(data?.marketing.other_settings.letStartScreen)
                    setGenderScreen(data?.marketing.other_settings.genderScreen)
                    setNextScreen(data?.marketing.other_settings.nextScreen)
                    setNextInnerScreen(data?.marketing.other_settings.nextInnerScreen)
                    setConnectScreen(data?.marketing.other_settings.connectScreen)
                    setStartAgainScreen(data?.marketing.other_settings.startScreen)
                    setCastingFlow(data?.marketing.other_settings.castingFlow)
                    setDialogApp(data?.marketing.other_settings.dialogApp)
                    setAdditionalFields(data?.marketing?.other_settings?.additionalFields)
                }
            }
        } else {
            navigate("/apps");
        }
    }, [])
    const createNotification = () => {
        NotificationManager.success('Record updated successfully');
    }
    const handleOtherSettingData = async () => {
        const organicData = JSON.parse(localStorage.getItem("appAllData"))

        for (let field of additionalFields) {
            if (field.type == "radio-textbox" && field.enabled == "false") {
                field.value = "false"
            }
        }

        const other_settings = {
            allAds: allAds,
            screenNavigationFull: screenNavigationFull,
            versionCode: addBlock,
            continueScreen: continueScreen,
            letStartScreen: startScreen,
            genderScreen: genderScreen,
            nextScreen: nextScreen,
            nextInnerScreen: nextInnerScreen,
            connectScreen: connectScreen,
            startScreen: startAgainScreen,
            castingFlow: castingFlow,
            dialogApp: dialogApp,
            additionalFields
            // addBlock: addBlock
        }
        organicData["marketing"] = { ...organicData["marketing"], other_settings }

        const res = await apiServices.updateApp(organicData, localStorage.getItem("id"), organicData.console_id);
        console.log("MarketingResOther++++", res)
        if (res) {
            localStorage.setItem("appAllData", JSON.stringify(res.AppData))
            createNotification('success')

        }
        console.log("responseOther", res)
    }


    return (
        <>
            <Header />
            {localStorage.getItem("token")
                &&
                <div className="col-lg-2">
                    <EditUserSideBar />
                </div>
            }
            <NotificationContainer />

            <div className={`${localStorage.getItem("token") ? "col-lg-10" : "col-lg-12"}`}>
                <div className='other-container'>
                    <div className='heading-other-setting'>
                        <h3>Other Setting</h3>
                    </div>
                    <div className='row'>

                        <div className='col-lg-5'>
                            <div className='d-flex justify-content-between'>
                                <p> All Ads</p>
                                <div>:</div>
                            </div>
                        </div>
                        <div className='col-lg-7 d-flex'>

                            <Redio
                                changed={handleAllAdsRadio}
                                className={""}
                                name={"all-ads"}
                                id={"all-ads-show"}
                                isSelected={allAds == "true"}
                                label="Show"
                                value={"true"}
                            />
                            <Redio
                                changed={handleAllAdsRadio}
                                className={""}
                                name={"all-ads"}
                                id={"all-ads-hide"}
                                isSelected={allAds == "false"}
                                label="Hide"
                                value={"false"}
                            />
                        </div>

                        {/* } */}


                        <div className='col-lg-5 '>
                            <div className=' d-flex justify-content-between'>
                                <p> Full Screeen (Navigation)</p>
                                <div>:</div>
                            </div>
                        </div>
                        <div className='col-lg-7 d-flex'>

                            <Redio
                                changed={handleScreenNavigation}
                                className={""}
                                name={"screen-navigation-a"}
                                id={"navigation-show"}
                                isSelected={screenNavigationFull == "true"}
                                label="Show"
                                value={"true"}
                            />
                            <Redio
                                changed={handleScreenNavigation}
                                className={""}
                                name={"screen-navigation-a"}
                                id={"navigation-hide"}
                                isSelected={screenNavigationFull == "false"}
                                label="Hide"
                                value={"false"}
                            />
                        </div>

                        <div className='col-lg-5'>
                            <div className='d-flex justify-content-between'>
                                <p> VersionCode for Add Block</p>
                                <div>:</div>
                            </div>
                        </div>
                        <div className='col-lg-7'>
                            <div>
                                <input
                                    type="text"
                                    name="add-block"
                                    value={addBlock}
                                    id="add-block"
                                    onChange={(e) => setAddBlock(e.target.value)}
                                />
                            </div>
                        </div>

                        <div className='col-lg-5'>
                            <div className=' d-flex justify-content-between'>
                                <p> Continue Screen</p>
                                <div>:</div>
                            </div>
                        </div>
                        <div className='col-lg-7 d-flex'>
                            <Redio
                                changed={handleContinueScreen}
                                className={""}
                                name={"continue-screen"}
                                id={"continue-screen-show"}
                                isSelected={continueScreen == "true"}
                                label="Show"
                                value={"true"}
                            />
                            <Redio
                                changed={handleContinueScreen}
                                className={""}
                                name={"continue-screen"}
                                id={"continue-screen-hide"}
                                isSelected={continueScreen == "false"}
                                label="Hide"
                                value={"false"}
                            />
                        </div>

                        <div className='col-lg-5'>
                            <div className=' d-flex justify-content-between'>
                                <p> Let's Start Screen</p>
                                <div>:</div>
                            </div>
                        </div>
                        <div className='col-lg-7 d-flex'>
                            <Redio
                                changed={handleLetsStartScreen}
                                className={""}
                                name={"start-screen"}
                                id={"start-screen-show"}
                                isSelected={startScreen == "true"}
                                label="Show"
                                value={"true"}
                            />
                            <Redio
                                changed={handleLetsStartScreen}
                                className={""}
                                name={"start-screen"}
                                id={"start-screen-hide"}
                                isSelected={startScreen == "false"}
                                label="Hide"
                                value={"false"}
                            />
                        </div>

                        <div className='col-lg-5'>
                            <div className=' d-flex justify-content-between'>
                                <p> Age/Gender Start Screen</p>
                                <div>:</div>
                            </div>
                        </div>
                        <div className='col-lg-7 d-flex'>
                            <Redio
                                changed={handleGenderScreen}
                                className={""}
                                name={"gender-screen"}
                                id={"gender-screen-show"}
                                isSelected={genderScreen == "true"}
                                label="Show"
                                value={"true"}
                            />
                            <Redio
                                changed={handleGenderScreen}
                                className={""}
                                name={"gender-screen"}
                                id={"gender-screen-hide"}
                                isSelected={genderScreen == "false"}
                                label="Hide"
                                value={"false"}
                            />
                        </div>

                        <div className='col-lg-5'>
                            <div className=' d-flex justify-content-between'>
                                <p> Next Screen</p>
                                <div>:</div>
                            </div>
                        </div>
                        <div className='col-lg-7 d-flex'>
                            <Redio
                                changed={handleNextScreen}
                                className={""}
                                name={"next-screen"}
                                id={"next-screen-show"}
                                isSelected={nextScreen == "true"}
                                label="Show"
                                value={"true"}
                            />
                            <Redio
                                changed={handleNextScreen}
                                className={""}
                                name={"next-screen"}
                                id={"next-screen-hide"}
                                isSelected={nextScreen == "false"}
                                label="Hide"
                                value={"false"}
                            />
                        </div>


                        <div className='col-lg-5'>
                            <div className=' d-flex justify-content-between'>
                                <p> Next Inner Screen</p>
                                <div>:</div>
                            </div>
                        </div>
                        <div className='col-lg-7 d-flex'>
                            <Redio
                                changed={handleNextInnerScreen}
                                className={""}
                                name={"next-inner-screen"}
                                id={"next-inner-screen-show"}
                                isSelected={nextInnerScreen == "true"}
                                label="Show"
                                value={"true"}
                            />
                            <Redio
                                changed={handleNextInnerScreen}
                                className={""}
                                name={"next-inner-screen"}
                                id={"next-inner-screen-hide"}
                                isSelected={nextInnerScreen == "false"}
                                label="Hide"
                                value={"false"}
                            />

                        </div>

                        <div className='col-lg-5'>
                            <div className=' d-flex justify-content-between'>
                                <p> Connect Screen</p>
                                <div>:</div>
                            </div>
                        </div>
                        <div className='col-lg-7 d-flex'>
                            <Redio
                                changed={handleConnectScreen}
                                className={""}
                                name={"connect-screen"}
                                id={"connect-screen-show"}
                                isSelected={connectScreen == "true"}
                                label="Show"
                                value={"true"}
                            />
                            <Redio
                                changed={handleConnectScreen}
                                className={""}
                                name={"connect-screen"}
                                id={"connect-screen-hide"}
                                isSelected={connectScreen == "false"}
                                label="Hide"
                                value={"false"}
                            />
                        </div>


                        <div className='col-lg-5'>
                            <div className=' d-flex justify-content-between'>
                                <p>Start Screen</p>
                                <div>:</div>
                            </div>
                        </div>
                        <div className='col-lg-7 d-flex'>
                            <Redio
                                changed={handleStartScreen}
                                className={""}
                                name={"start-again-screen"}
                                id={"start-again-screen-show"}
                                isSelected={startAgainScreen == "true"}
                                label="Show"
                                value={"true"}
                            />
                            <Redio
                                changed={handleStartScreen}
                                className={""}
                                name={"start-again-screen"}
                                id={"start-again-screen-hide"}
                                isSelected={startAgainScreen == "false"}
                                label="Hide"
                                value={"false"}
                            />
                        </div>


                        <div className='col-lg-5'>
                            <div className=' d-flex justify-content-between'>
                                <p>Real Casting Flow</p>
                                <div>:</div>
                            </div>
                        </div>
                        <div className='col-lg-7 d-flex'>
                            <Redio
                                changed={handleCastinFlow}
                                className={""}
                                name={"casting-flow"}
                                id={"casting-flow-show"}
                                isSelected={castingFlow == "true"}
                                label="Show"
                                value={"true"}
                            />
                            <Redio
                                changed={handleCastinFlow}
                                className={""}
                                name={"casting-flow"}
                                id={"casting-flow-hide"}
                                isSelected={castingFlow == "false"}
                                label="Hide"
                                value={"false"}
                            />
                        </div>

                        <div className='col-lg-5'>
                            <div className=' d-flex justify-content-between'>
                                <p>Dialog For App Stop</p>
                                <div>:</div>
                            </div>
                        </div>
                        <div className='col-lg-7 d-flex'>
                            <Redio
                                changed={handleDialogApp}
                                className={""}
                                name={"dialoge-app"}
                                id={"dialoge-app-show"}
                                isSelected={dialogApp == "true"}
                                label="Show"
                                value={"true"}
                            />
                            <Redio
                                changed={handleDialogApp}
                                className={""}
                                name={"dialoge-app"}
                                id={"dialoge-app-hide"}
                                isSelected={dialogApp == "false"}
                                label="Hide"
                                value={"false"}
                            />
                        </div>

                        {additionalFields?.map((field, index) => (
                            <>
                                <div className='col-lg-5'>
                                    <div className=' d-flex justify-content-between'>
                                        <p>{field.name}</p>
                                        <div>:</div>
                                    </div>
                                </div>
                                {field.type == "textbox" &&
                                    <div className='col-lg-7 d-flex'>
                                        <input
                                            type="text"
                                            name={`additional-field-${index}`}
                                            value={field.value}
                                            id={`additional-field-${index}`}
                                            onChange={(e) => { field.value = e.target.value; setAdditionalFields([...additionalFields]); }}
                                        />
                                    </div>
                                }
                                {field.type == "radio" &&
                                    <div className='col-lg-7 d-flex'>
                                        <Redio
                                            changed={(e) => { field.value = e.target.value; setAdditionalFields([...additionalFields]); }}
                                            className={""}
                                            name={`additional-field-${index}`}
                                            id={`additional-field-show${index}`}
                                            isSelected={field.value == "true"}
                                            label="Show"
                                            value={"true"}
                                        />
                                        <Redio
                                            changed={(e) => { field.value = e.target.value; setAdditionalFields([...additionalFields]); }}
                                            className={""}
                                            name={`additional-field-${index}`}
                                            id={`additional-field-hide-${index}`}
                                            isSelected={field.value == "false"}
                                            label="Hide"
                                            value={"false"}
                                        />

                                    </div>
                                }
                                {field.type == "radio-textbox" &&
                                    <div className='col-lg-7 d-flex'>
                                        <Redio
                                            changed={(e) => { field.enabled = e.target.value; setAdditionalFields([...additionalFields]); }}
                                            className={""}
                                            name={`additional-field-${index}`}
                                            id={`additional-field-show-${index}`}
                                            isSelected={field.enabled == "true"}
                                            label="Show"
                                            value={"true"}
                                        />
                                        <Redio
                                            changed={(e) => { field.enabled = e.target.value; setAdditionalFields([...additionalFields]); }}
                                            className={""}
                                            name={`additional-field-${index}`}
                                            id={`additional-field-hide-${index}`}
                                            isSelected={field.enabled == "false"}
                                            label="Hide"
                                            value={"false"}
                                        />
                                        <input
                                            type="text"
                                            name={`additional-field-textbox-${index}`}
                                            value={field.value}
                                            id={`additional-field-${index}`}
                                            onChange={(e) => { field.value = e.target.value; setAdditionalFields([...additionalFields]); }}
                                            disabled={field.enabled == "true" ? false : true}
                                        />
                                    </div>
                                }
                            </>
                        )
                        )}

                    </div>


                </div>
                <div className='d-flex'>
                    <AddButton
                        onClick={handleOtherSettingData}
                        buttonName={"Update"}
                    />

                    <AddButton buttonName={"Add field"}
                        buttonStyle={"other-add-field"}
                        onClick={handleShow} />
                </div>
            </div>



            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>New Field</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className="mb-3" >
                        <Form.Label>Field Name</Form.Label>
                        <Form.Control type="text" placeholder="field name" value={newFieldName} onChange={e => setNewFieldName(e.target.value)} />
                    </Form.Group>
                    <Form.Group className='mb-3'>
                        <Form.Label>Type</Form.Label>
                        <Redio
                            changed={(e) => { setNewFieldType(e.target.value) }}
                            className={""}
                            name={`additional-field-type`}
                            id={`additional-field-type-radio`}
                            isSelected={newFieldType == "radio"}
                            label="Radio"
                            value={"radio"}
                        />
                        <Redio
                            changed={(e) => { setNewFieldType(e.target.value) }}
                            className={""}
                            name={`additional-field-type`}
                            id={`additional-field-type-textbox`}
                            isSelected={newFieldType == "textbox"}
                            label="Textbox"
                            value={"textbox"}
                        />
                        <Redio
                            changed={(e) => { setNewFieldType(e.target.value) }}
                            className={""}
                            name={`additional-field-type`}
                            id={`additional-field-type-radio-textbox`}
                            isSelected={newFieldType == "radio-textbox"}
                            label="Radio + Textbox"
                            value={"radio-textbox"}
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleAddField}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default OtherSettingOrganic