import React from 'react'
import { useState, useEffect } from 'react'

const JsonData = () => {
    const [jsonData, setJsonData] = useState({});

    useEffect(() => {
        if (localStorage.getItem("id")) {
            if (localStorage.getItem("appAllData")) {
                const data = JSON.parse(localStorage.getItem("appAllData"))
                console.log("appAllData++++++++", data)
                setJsonData(data)
            }
        }
    }, [])




    return (
        <>

            <pre>{JSON.stringify(jsonData.organic, null, 2)}</pre>

        </>
    )
}

export default JsonData