import React, { useEffect, useState } from "react";
import EditUserSideBar from "../Components/Sidebar/EditUserSideBar";
import "../Css/addSetting.css";
import { Redio } from "../Components/Redio/Redio";
import AddButton from "../Components/Button/AddButton";
import apiServices from "../services/apiServices";
import { useLocation, useNavigate } from "react-router";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { TagsInput } from "react-tag-input-component";
import Header from "../Components/Header/Header";
import { Button, Form, Modal } from "react-bootstrap";

const BifurcateOrganic = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [adminAppColor, setAdminAppColor] = useState("#000000");
  const [nativeBackground, setnativeBackground] = useState("#FFFEFF");
  const [nativeBtnBackground, setnativeBtnBackground] = useState("#4285F4");
  const [nativeText, setnativeText] = useState("#808080");
  const [nativeButtonText, setnativeButtonText] = useState("#FFFEFF");
  const [appBackgroundColor, setAppBackgroundColor] = useState("#000000");
  const [bottombannerRedio, setBottomBannerRedio] = useState("bottom-banner");
  const [screenNative, setScreenNative] = useState("false");
  const [listNative, setListNative] = useState("false");
  const [listNativeInput, setListNativeInput] = useState("0");
  const [nativeCount, setNativeCount] = useState("0");
  const [exitDialoge, setExitDialogeNative] = useState("false");
  const [nativeBtnText, setnativeBtnText] = useState("default");
  const [nativeBtnLabel, setNativeBtnLabel] = useState("");
  const [spleshAddData, setSpleshAddData] = useState("OpenAds");
  const [appOpenData, setAppOpenData] = useState("Every Time");
  const [interData, setInterData] = useState("false");
  const [blackClick, setBlackClick] = useState("4");
  const [interInterval, setInterInterval] = useState("4");
  const [localStorageData, setLocalStorageData] = useState({});
  const [selectedCountryLocation, setSelectedCountryLocation] = useState([
    "Google LLC",
  ]);
  const [addBlock, setAddBlock] = useState("");
  const [allAds, setAllAds] = useState("false");
  const [screenNavigationFull, setScreenNavigationFull] = useState("false");
  const [continueScreen, setContinueScreen] = useState("false");
  const [startScreen, setStartScreen] = useState("false");
  const [genderScreen, setGenderScreen] = useState("false");
  const [nextScreen, setNextScreen] = useState("false");
  const [nextInnerScreen, setNextInnerScreen] = useState("false");
  const [connectScreen, setConnectScreen] = useState("false");
  const [startAgainScreen, setStartAgainScreen] = useState("false");
  const [castingFlow, setCastingFlow] = useState("false");
  const [dialogApp, setDialogApp] = useState("false");
  const [nativeLoading, setNativeLoading] = useState("false");
  const [vpn, setVpn] = useState("false");
  const [screenVpnDialog, setscreenVpnDialog] = useState("false");
  const [vpmDialogOpen, setVpmDialogOpen] = useState("false");
  const [vpnOrganicData, setVpnOrganicData] = useState({
    vpnUrl: "",
    vpnId: "",
  });
  const [VpnListCountry, setVpnListCountry] = useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [newFieldName, setNewFieldName] = useState("");
  const [newFieldType, setNewFieldType] = useState("radio");
  const [appOpenLoading, setAppOpenLoading] = useState("false");

  const [additionalFields, setAdditionalFields] = useState([]);
  const [alternateShow, setAlternateShow] = useState("false");

  console.log("additionalFields::::", additionalFields);
  useEffect(() => { }, [additionalFields]);

  // Other setting
  const handleAddField = () => {
    console.log("name::::", newFieldName);
    let field = {
      name: newFieldName,
      value: "false",
      type: newFieldType,
    };
    if (field.type == "radio-textbox") {
      field.enabled = "true";
      field.value = "";
    }
    setNewFieldName("");
    setNewFieldType("radio");
    setAdditionalFields([...additionalFields, field]);
    setShow(false);
  };

  const handleVpnOrganicInput = (event) => {
    const { name, value } = event.target;
    setVpnOrganicData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleVpn = (e) => {
    setVpn(e.target.value);
  };
  const handleVpmDialog = (e) => {
    setscreenVpnDialog(e.target.value);
  };
  const handleVpmDialogOpen = (e) => {
    setVpmDialogOpen(e.target.value);
  };

  const handleNativebtnText = (e) => {
    setnativeBtnText(e.target.value);
  };
  const exitDialogeNative = (e) => {
    setExitDialogeNative(e.target.value);
  };
  const radioBottomBannerChangeHandler = (e) => {
    setBottomBannerRedio(e.target.value);
  };
  const radioAllScreenNativeChangeHandler = (e) => {
    setScreenNative(e.target.value);
  };
  const handleListNative = (e) => {
    setListNative(e.target.value);
    if (e.target.value == "false") {
      setListNativeInput("0");
    }
  };
  const handleListNativeInput = (e) => {
    setListNativeInput(e.target.value);
  };
  const handleNativeCount = (e) => {
    setNativeCount(e.target.value);
  };
  const handleNativeLoading = (e) => {
    setNativeLoading(e.target.value);
  };
  const createNotification = () => {
    NotificationManager.success("Record updated successfully");
  };

  // const handleCountryLocation = (e) => {
  //     setSelectedCountryLocation(e.target.value)
  // }

  // other setting
  const handleAllAdsRadio = (e) => {
    setAllAds(e.target.value);
    // console.log("value", e.target.value)
  };

  const handleScreenNavigation = (e) => {
    setScreenNavigationFull(e.target.value);
  };
  const handleContinueScreen = (e) => {
    setContinueScreen(e.target.value);
  };
  const handleLetsStartScreen = (e) => {
    setStartScreen(e.target.value);
  };
  const handleGenderScreen = (e) => {
    setGenderScreen(e.target.value);
  };
  const handleNextScreen = (e) => {
    setNextScreen(e.target.value);
  };
  const handleNextInnerScreen = (e) => {
    setNextInnerScreen(e.target.value);
  };
  const handleConnectScreen = (e) => {
    setConnectScreen(e.target.value);
  };
  const handleStartScreen = (e) => {
    setStartAgainScreen(e.target.value);
  };
  const handleCastinFlow = (e) => {
    setCastingFlow(e.target.value);
  };
  const handleDialogApp = (e) => {
    setDialogApp(e.target.value);
  };
  const handleAddSettingOrganic = async () => {
    const organicData = JSON.parse(localStorage.getItem("appAllData"));
    for (let field of additionalFields) {
      if (field.type == "radio-textbox" && field.enabled == "false") {
        field.value = "false";
      }
    }
    const bifurcate = {
      location: selectedCountryLocation,
      other_settings: {
        allAds: allAds,
        screenNavigationFull: screenNavigationFull,
        versionCode: addBlock,
        continueScreen: continueScreen,
        letStartScreen: startScreen,
        genderScreen: genderScreen,
        nextScreen: nextScreen,
        nextInnerScreen: nextInnerScreen,
        connectScreen: connectScreen,
        startScreen: startAgainScreen,
        castingFlow: castingFlow,
        dialogApp: dialogApp,
        additionalFields,
        // addBlock: addBlock
      },
      vpn_settings: {
        vpn: vpn,
        vpn_dialog: screenVpnDialog,
        vpn_dialog_open: vpmDialogOpen,
        vpn_list_country: VpnListCountry,
        vpn_url: vpnOrganicData.vpnUrl,
        vpn_carrier_id: vpnOrganicData.vpnId,
      },
      ads_settings: {
        app_color: {
          app_color_for_admin: adminAppColor,
          background_color: appBackgroundColor,
        },
        native: {
          native_loading: nativeLoading,
          bottom_banner:
            bottombannerRedio == "bottom-banner"
              ? "banner"
              : bottombannerRedio == "bottom-hide"
                ? "hide"
                : "native",
          all_screen_native: screenNative,
          list_native: listNative === "true" ? listNativeInput : "0",
          static_native_count: nativeCount,
          exit_dialog_native: exitDialoge,
          native_button_text:
            nativeBtnText == "default" ? "Default" : nativeBtnLabel,
          native_color: {
            background: nativeBackground,
            text: nativeText,
            button_background: nativeBtnBackground,
            button_text: nativeButtonText,
          },
        },
        inter: {
          inter_interval: interInterval,
          back_click_inter: blackClick,
          inter_loading: interData,
          alternate_app: alternateShow,
        },
        app_open: {
          splash_ads: spleshAddData,
          app_open: appOpenData,
          app_open_loading: appOpenLoading,
        },
      },
    };
    organicData["organic"] = { ...organicData["organic"], bifurcate };
    // const data = {
    //     ads_settings: {
    //         app_color: {
    //             app_color_for_admin: adminAppColor,
    //             background_color: appBackgroundColor
    //         },
    //         native: {
    //             bottom_banner: bottombannerRedio == "bottom-banner" ? "banner" : bottombannerRedio == "bottom-hide" ? "hide" : "native",
    //             all_screen_native: screenNative,
    //             list_native: listNative,
    //             static_native_count: nativeCount,
    //             exit_dialog_native: exitDialoge,
    //             native_button_text: nativeBtnText == "default" ? "Default" : nativeBtnLabel,
    //             native_color: {
    //                 background: nativeBackground,
    //                 text: nativeText,
    //                 button_background: nativeBtnBackground,
    //                 button_text: nativeButtonText
    //             }
    //         },
    //         inter: {
    //             inter_interval: interInterval,
    //             back_click_inter: blackClick,
    //             show_loader_before_inter: interData
    //         },
    //         app_open: {
    //             splash_ads: spleshAddData,
    //             app_open: appOpenData
    //         },
    //     },
    // }
    const res = await apiServices.updateApp(
      organicData,
      localStorage.getItem("id"),
      organicData.console_id
      // "organic"
    );
    console.log("resOrgannicBifurcate+++", res);

    if (res) {
      localStorage.setItem("appAllData", JSON.stringify(res.AppData));
      createNotification("success");
    }
  };
  useEffect(() => {
    document.body.classList.add("addSetting-body");
    // console.log("localStorage.getItemid)", localStorage.getItem("id"))
    if (localStorage.getItem("id")) {
      // console.log("localStorage.getItem::", localStorage.getItem("appAllData"))
      if (localStorage.getItem("appAllData")) {
        const data = JSON.parse(localStorage.getItem("appAllData"));
        console.log("AppAllDataBifurcateOrganic", data);
        setLocalStorageData(data);
        if (data.organic.bifurcate != null) {
          setSelectedCountryLocation(data?.organic?.bifurcate?.location);
          setAdminAppColor(
            data?.organic?.bifurcate?.ads_settings?.app_color
              .app_color_for_admin == null
              ? "#4899e5"
              : data?.organic?.bifurcate?.ads_settings?.app_color
                .app_color_for_admin
          );
          setAppBackgroundColor(
            data?.organic?.bifurcate?.ads_settings?.app_color
              .background_color == null
              ? "#F2F2F2"
              : data?.organic?.bifurcate?.ads_settings?.app_color
                .background_color
          );
          setNativeLoading(
            data?.organic?.ads_settings?.native?.native_loading == null
              ? "false"
              : data?.organic?.ads_settings?.native?.native_loading
          );
          setBottomBannerRedio(
            data?.organic?.bifurcate?.ads_settings?.native?.bottom_banner ==
              null
              ? "bottom-native"
              : data?.organic?.bifurcate?.ads_settings?.native?.bottom_banner ==
                "native"
                ? "bottom-native"
                : data?.organic?.bifurcate?.ads_settings?.native?.bottom_banner ==
                  "hide"
                  ? "bottom-hide"
                  : "bottom-banner"
          );
          setScreenNative(
            data?.organic?.bifurcate?.ads_settings?.native.all_screen_native ==
              null
              ? "true"
              : data?.organic?.bifurcate?.ads_settings?.native.all_screen_native
          );

          setListNative(
            data?.organic?.bifurcate?.ads_settings?.native?.list_native == "0"
              ? "false"
              : "true"
          );
          setListNativeInput(
            data?.organic?.bifurcate?.ads_settings?.native?.list_native ==
              "false"
              ? "0"
              : data?.organic?.bifurcate?.ads_settings?.native?.list_native
          );

          setExitDialogeNative(
            data?.organic?.bifurcate?.ads_settings?.native
              ?.exit_dialog_native == null
              ? "false"
              : data?.organic?.bifurcate?.ads_settings?.native
                ?.exit_dialog_native
          );

          setNativeCount(
            data?.organic?.bifurcate?.ads_settings?.native
              ?.static_native_count == null
              ? "0"
              : data?.organic?.bifurcate?.ads_settings?.native
                ?.static_native_count
          );

          setnativeBtnText(
            data?.organic?.bifurcate?.ads_settings?.native
              ?.native_button_text == null
              ? "default"
              : data?.organic?.bifurcate?.ads_settings?.native
                ?.native_button_text == "Default"
                ? "default"
                : "Manual"
          );
          setNativeBtnLabel(
            data?.organic?.bifurcate?.ads_settings?.native
              ?.native_button_text == "Default"
              ? ""
              : data?.organic?.bifurcate?.ads_settings?.native
                ?.native_button_text
          );

          // setnativeBtnText(data?.organic?.bifurcate
          //     ?.ads_settings?.native.native_button_text == null ? "defaults" : data?.organic?.bifurcate
          //         ?.ads_settings?.native.native_button_text == "Default" ? "default" : data?.organic?.bifurcate
          //             ?.ads_settings?.native?.native_button_text)

          setnativeBackground(
            data?.organic?.bifurcate?.ads_settings?.native.native_color
              .background == null
              ? "#F2F2F2"
              : data?.organic?.bifurcate?.ads_settings?.native.native_color
                .background
          );
          setnativeBtnBackground(
            data?.organic?.bifurcate?.ads_settings?.native.native_color
              .button_background == null
              ? "#31814d"
              : data?.organic?.bifurcate?.ads_settings?.native.native_color
                .button_background
          );
          setnativeText(
            data?.organic?.bifurcate?.ads_settings?.native.native_color.text ==
              null
              ? "#000000"
              : data?.organic?.bifurcate?.ads_settings?.native.native_color.text
          );
          setnativeButtonText(
            data?.organic.bifurcate?.ads_settings?.native.native_color
              .button_text == null
              ? "#FFFFFF"
              : data?.organic?.bifurcate?.ads_settings?.native.native_color
                .button_text
          );

          setInterInterval(
            data?.organic?.bifurcate?.ads_settings?.inter?.inter_interval ==
              null
              ? "0"
              : data?.organic?.bifurcate?.ads_settings?.inter?.inter_interval
          );
          setBlackClick(
            data?.organic?.bifurcate?.ads_settings?.inter?.back_click_inter ==
              null
              ? "0"
              : data?.organic.bifurcate?.ads_settings?.inter?.back_click_inter
          );
          setAlternateShow(
            data?.organic.bifurcate?.ads_settings?.inter?.alternate_app == null
              ? "false"
              : data?.organic?.bifurcate?.ads_settings?.inter?.alternate_app
          );
          setInterData(
            data?.organic.bifurcate?.ads_settings?.inter?.inter_loading == null
              ? "false"
              : data?.organic?.bifurcate?.ads_settings?.inter?.inter_loading
          );
          setSpleshAddData(
            data?.organic?.bifurcate?.ads_settings?.app_open?.splash_ads == null
              ? "OpenAds"
              : data?.organic?.bifurcate?.ads_settings?.app_open?.splash_ads
          );
          setAppOpenData(
            data?.organic?.bifurcate?.ads_settings?.app_open?.app_open == null
              ? "Every Time"
              : data?.organic?.bifurcate?.ads_settings?.app_open?.app_open
          );
          setAppOpenLoading(
            data?.organic?.ads_settings?.app_open?.app_open_loading == null
              ? "false"
              : data?.organic?.ads_settings?.app_open?.app_open_loading
          );

          setVpnOrganicData({
            vpnUrl: data?.organic?.bifurcate?.vpn_settings?.vpn_url,
            vpnId: data?.organic?.bifurcate?.vpn_settings?.vpn_carrier_id,
          });
          setVpnListCountry(
            data?.organic?.bifurcate?.vpn_settings?.vpn_list_country
          );
          setVpn(data?.organic?.bifurcate?.vpn_settings?.vpn);
          setVpmDialogOpen(
            data?.organic?.bifurcate?.vpn_settings?.vpn_dialog_open
          );
          setscreenVpnDialog(
            data?.organic?.bifurcate?.vpn_settings?.vpn_dialog
          );

          setAddBlock(data?.organic?.bifurcate?.other_settings?.addBlock);
          setAllAds(
            data?.organic?.bifurcate?.other_settings?.allAds || "false"
          );
          setScreenNavigationFull(
            data?.organic?.bifurcate?.other_settings?.screenNavigationFull ||
            "false"
          );
          setContinueScreen(
            data?.organic?.bifurcate?.other_settings?.continueScreen || "false"
          );
          setStartScreen(
            data?.organic?.bifurcate?.other_settings?.letStartScreen || "false"
          );
          setGenderScreen(
            data?.organic?.bifurcate?.other_settings?.genderScreen || "false"
          );
          setNextScreen(
            data?.organic?.bifurcate?.other_settings?.nextScreen || "false"
          );
          setNextInnerScreen(
            data?.organic?.bifurcate?.other_settings?.nextInnerScreen || "false"
          );
          setConnectScreen(
            data?.organic?.bifurcate?.other_settings?.connectScreen || "false"
          );
          setStartAgainScreen(
            data?.organic?.bifurcate?.other_settings?.startScreen || "false"
          );
          setCastingFlow(
            data?.organic?.bifurcate?.other_settings?.castingFlow || "false"
          );
          setDialogApp(
            data?.organic?.bifurcate?.other_settings?.dialogApp || "false"
          );
          setAdditionalFields(
            data?.organic?.bifurcate?.other_settings?.additionalFields
          );
        }
      }
    } else {
      navigate("/apps");
    }
  }, []);
  return (
    <>
      <Header />
      {localStorage.getItem("token") && (
        <div className="col-lg-2">
          <EditUserSideBar />
        </div>
      )}
      <NotificationContainer />
      <div
        className={`${localStorage.getItem("token") ? "col-lg-10" : "col-lg-12"
          }`}
      >
        <div className="row">
          {/* setting-area */}
          <div className="col-9 p-0 ps-3">
            {/* bifurcate field */}
            <div className="bifurcate-field-conatiner">
              <div className="main-title">Bifurcate </div>
              {/* <div className='row align-items-center mb-4'>
                                <div className='col-3 d-flex justify-content-between'>
                                    <div className='title'>Location List</div>
                                    <div>:</div>
                                </div>

                                <div className='col-9 ps-4'>
                                    <div className="d-flex align-items-center">
                                        <Redio
                                            changed={handleBifurcateRadio}
                                            // className={"    "}
                                            name="location"
                                            id={"bifurcate-allow"}
                                            isSelected={locationList == "true"}
                                            label="Allow"
                                            value={"true"}
                                        />
                                        <Redio
                                            changed={handleBifurcateRadio}
                                            className={""}
                                            name="location"
                                            id={"bifurcate-block"}
                                            isSelected={locationList == "false"}
                                            label="Block"
                                            value={"false"}
                                        />

                                    </div>
                                </div>
                            </div> */}
              <div className="row align-items-center">
                <div className="col-lg-3 d-flex justify-content-between">
                  <p> Location </p>
                  <div>:</div>
                </div>
                <div className="col-lg-9">
                  <TagsInput
                    value={selectedCountryLocation}
                    onChange={setSelectedCountryLocation}
                    name="Country"
                  // placeHolder="enter fruits"
                  />
                  {/* <div className="form-group">
                                        <input
                                            type="text"
                                            name="location-organic"
                                            value={selectedCountryLocation}
                                            className="form-control"
                                            id="location"
                                            placeholder=""
                                            onChange={handleCountryLocation}
                                        />
                                    </div> */}
                  {/* <Select options={Countries}
                                        components={animatedComponents}
                                        value={selectedCountryLocation}
                                        onChange={handleCountryLocation}
                                        isMulti /> */}
                </div>
              </div>
            </div>
            {/* app color container */}
            <div className="app-color-container mt-1 mb-4">
              <div className="main-title">App Color</div>
              {/* app color-panel */}
              <div className="row justify-content-between pt-3">
                {/* admin app color */}
                <div className="row align-items-center col-6 admin-app-color-section">
                  <div className="col-6 d-flex justify-content-between">
                    <div className="title">App color for admin</div>
                    <div>:</div>
                  </div>
                  <div className="col-6">
                    {/* <div className="container-color d-flex align-items-center"> */}
                    <input
                      type="text"
                      id="color-picker"
                      onChange={(e) => setAdminAppColor(e.target.value)}
                      value={adminAppColor}
                    />
                    {/* <span className='ps-2'>{adminAppColor}</span> */}
                    {/* </div> */}
                  </div>
                </div>
                <div className="row align-items-center col-6 admin-app-color-section">
                  <div className="col-6 d-flex justify-content-between">
                    <div className="title">Background color</div>
                    <div>:</div>
                  </div>
                  <div className="col-6">
                    {/* <div className="container-color d-flex align-items-center"> */}
                    <input
                      type="text"
                      // id="color-picker"
                      onChange={(e) => setAppBackgroundColor(e.target.value)}
                      value={appBackgroundColor}
                    />
                    {/* <span className="ps-2">{appBackgroundColor}</span> */}
                    {/* </div> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="native-container mb-4">
              <div className="main-title mb-3">Native</div>
              {/* Inter loading */}
              <div className="row align-items-center  admin-app-color-section mt-2">
                <div className="col-3 d-flex justify-content-between">
                  <div className="title">Native Loading</div>
                  <div>:</div>
                </div>
                <div className="col-9 ps-4">
                  <div className="d-flex align-items-center">
                    <Redio
                      changed={handleNativeLoading}
                      className={""}
                      name={"native-loading"}
                      id={"native-show"}
                      isSelected={nativeLoading == "true"}
                      label="Preload"
                      value={"true"}
                    />
                    <Redio
                      changed={handleNativeLoading}
                      name={"native-loading"}
                      id={"native-hide"}
                      isSelected={nativeLoading == "false"}
                      label="Onload"
                      value={"false"}
                    />
                  </div>
                </div>
              </div>
              {/* Bottom bannner */}
              <div className="row align-items-center  admin-app-color-section">
                <div className="col-3 d-flex justify-content-between">
                  <div className="title">Bottom Banner</div>
                  <div>:</div>
                </div>
                <div className="col-9 ps-4">
                  <div className="d-flex align-items-center">
                    <Redio
                      changed={radioBottomBannerChangeHandler}
                      // className={"    "}
                      name="banner"
                      id={"bottom-native"}
                      isSelected={bottombannerRedio == "bottom-native"}
                      label="Native"
                      value={"bottom-native"}
                    />
                    <Redio
                      changed={radioBottomBannerChangeHandler}
                      className={""}
                      name="banner"
                      id={"bottom-banner"}
                      isSelected={bottombannerRedio == "bottom-banner"}
                      label="Banner"
                      value={"bottom-banner"}
                    />
                    <Redio
                      changed={radioBottomBannerChangeHandler}
                      // className={"    "}
                      name="banner"
                      id={"bottom-hide"}
                      isSelected={bottombannerRedio == "bottom-hide"}
                      label="Hide"
                      value={"bottom-hide"}
                    />
                  </div>
                </div>
              </div>
              {/* all screen native */}
              <div className="row align-items-center  admin-app-color-section mt-2">
                <div className="col-3 d-flex justify-content-between">
                  <div className="title">All Screen Native</div>
                  <div>:</div>
                </div>
                <div className="col-9 ps-4">
                  <div className="d-flex align-items-center">
                    <Redio
                      changed={radioAllScreenNativeChangeHandler}
                      className={""}
                      name={"screen-show"}
                      id={"screen-show"}
                      isSelected={screenNative == "true"}
                      label="Show"
                      value={"true"}
                    />
                    <Redio
                      changed={radioAllScreenNativeChangeHandler}
                      name={"screen-hide"}
                      id={"screen-hide"}
                      isSelected={screenNative == "false"}
                      label="Hide"
                      value={"false"}
                    />
                  </div>
                </div>
              </div>
              {/* List Native */}
              <div className="row align-items-center  admin-app-color-section mt-2">
                <div className="col-3 d-flex justify-content-between">
                  <div className="title">List Native</div>
                  <div>:</div>
                </div>
                <div className="col-9 ps-4">
                  <div className="d-flex">
                    <Redio
                      changed={handleListNative}
                      className={""}
                      name={"List-Native-show"}
                      id={"List-Native-show"}
                      isSelected={listNative == "true"}
                      label="Show"
                      value={"true"}
                    />
                    <Redio
                      changed={handleListNative}
                      name={"List-Native-hide"}
                      id={"List-Native-hide"}
                      isSelected={listNative == "false"}
                      label="Hide"
                      value={"false"}
                    />
                    <input
                      type="number"
                      value={listNativeInput}
                      onChange={handleListNativeInput}
                      disabled={listNative == "false"}
                    />
                  </div>
                </div>
              </div>
              {/* Static native count */}
              <div className="row align-items-center  admin-app-color-section mt-2">
                <div className="col-3 d-flex justify-content-between">
                  <div className="title">Static Native Count</div>
                  <div>:</div>
                </div>
                <div className="col-9 ps-4">
                  <div className="d-flex">
                    <input
                      type="number"
                      value={nativeCount}
                      onChange={handleNativeCount}
                    />
                  </div>
                </div>
              </div>
              {/* exit Dialoge native */}
              <div className="row align-items-center  admin-app-color-section mt-2">
                <div className="col-3 d-flex justify-content-between">
                  <div className="title">Exit Dialoge Native</div>
                  <div>:</div>
                </div>
                <div className="col-9 ps-4">
                  <div className="d-flex align-items-center">
                    <Redio
                      changed={exitDialogeNative}
                      className={""}
                      name={"exit-Dialoge-show"}
                      id={"exit-Dialoge-show"}
                      isSelected={exitDialoge == "true"}
                      label="Show"
                      value={"true"}
                    />
                    <Redio
                      changed={exitDialogeNative}
                      name={"exit-Dialoge-hide"}
                      id={"exit-Dialoge-hide"}
                      isSelected={exitDialoge == "false"}
                      label="Hide"
                      value={"false"}
                    />
                  </div>
                </div>
              </div>
              {/* native button texct */}
              <div className="row align-items-center  admin-app-color-section mt-2">
                <div className="col-3 d-flex justify-content-between">
                  <div className="title">Native Button Text</div>
                  <div>:</div>
                </div>
                <div className="col-9 ps-4">
                  <div className="d-flex align-items-center">
                    <Redio
                      changed={handleNativebtnText}
                      className={""}
                      name={"Native-Button-Text-Manual"}
                      id={"Native-Button-Text-Manual"}
                      isSelected={nativeBtnText == "Manual"}
                      label="Manual"
                      value={"Manual"}
                    />
                    <Redio
                      changed={handleNativebtnText}
                      name={"Native-Button-Text-default"}
                      id={"Native-Button-Text-default"}
                      isSelected={nativeBtnText == "default"}
                      label="Default"
                      value={"default"}
                    />
                    <input
                      type="text"
                      value={nativeBtnLabel}
                      onChange={(e) => setNativeBtnLabel(e.target.value)}
                      disabled={nativeBtnText == "default"}
                    />
                  </div>
                </div>
              </div>
              {/* native color */}
              <div className="row align-items-center  admin-app-color-section mt-4">
                <div className="col-3 d-flex justify-content-between">
                  <div className="title">Native Color</div>
                  <div>:</div>
                </div>
                <div className="col-9 ps-4 row Native-Color-container container-color-box">
                  <div className="col-2">
                    {/* <div className="container-color d-flex align-items-center"> */}
                    <input
                      type="text"
                      // id="color-picker"
                      onChange={(e) => setnativeBackground(e.target.value)}
                      value={nativeBackground}
                    />
                    {/* <span className="ps-2">{nativeBackground}</span> */}
                    {/* </div> */}
                    <div>Background</div>
                  </div>
                  <div className="col-2">
                    {/* <div className="container-color d-flex align-items-center"> */}
                    <input
                      type="text"
                      // id="color-picker"
                      onChange={(e) => setnativeText(e.target.value)}
                      value={nativeText}
                    />
                    {/* <span className="ps-2">{nativeText}</span> */}
                    {/* </div> */}
                    <div>Text</div>
                  </div>
                  <div className="col-2">
                    {/* <div className="container-color d-flex align-items-center"> */}
                    <input
                      type="text"
                      // id="color-picker"
                      onChange={(e) => setnativeBtnBackground(e.target.value)}
                      value={nativeBtnBackground}
                    />
                    {/* <span className="ps-2">{nativeBtnBackground}</span> */}
                    {/* </div> */}
                    <div>Button Background</div>
                  </div>
                  <div className="col-2">
                    {/* <div className="container-color d-flex align-items-center"> */}
                    <input
                      type="text"
                      // id="color-picker"
                      onChange={(e) => setnativeButtonText(e.target.value)}
                      value={nativeButtonText}
                    />
                    {/* <span className="ps-2">{nativeButtonText}</span> */}
                    {/* </div> */}
                    <div>Button Text</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* preview area */}
          <div className="col-3 mt-2">
            <div
              className="mobile-preview-design"
              style={{ backgroundColor: appBackgroundColor }}
            >
              <div>
                {/* admin app name */}
                <div
                  className="preview-app-name-title text-center"
                  style={{ backgroundColor: adminAppColor }}
                >
                  App Name Title
                </div>
                {/* native-part */}
                <div
                  className="px-2 py-2"
                  style={{ backgroundColor: nativeBackground }}
                >
                  <img
                    src="/Images/nativeImage.png"
                    className="nativeImage-div"
                  />
                  <div className="ms-4" style={{ color: nativeText }}>
                    <div className="Test-Ad-title">Test Ad: Goggle Ads</div>
                    <div className="Test-Ad-subtitle">
                      Stay up to date with your Ads Check
                    </div>
                  </div>
                  <button
                    className="w-100 native-btn mt-2"
                    style={{
                      backgroundColor: nativeBtnBackground,
                      color: nativeButtonText,
                    }}
                  >
                    {nativeBtnText == "default" ? "Default" : nativeBtnLabel}
                  </button>
                </div>
              </div>
              {/* start-app-div */}
              <div className="start-app-div">
                <div className="px-2 start-app-btn-div">
                  <button className="w-100 start-app-button">Start App</button>
                </div>
                {/* banner */}
                {bottombannerRedio == "bottom-hide" ? (
                  ""
                ) : (
                  <div className="">
                    <img
                      className="banner-image-section"
                      src={
                        bottombannerRedio == "bottom-native"
                          ? "/Images/nativeBanner.jpg"
                          : "/Images/banner.jpg"
                      }
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-6">
            <div className="native-container">
              <div className="app-color-container mt-1">
                <div className="main-title">Inter</div>
                <div className="row mt-2 admin-app-open">
                  <div className="col-5 mb-3 d-flex justify-content-between">
                    <div className="title"> Alternate AppOpen </div>
                    <div>:</div>
                  </div>
                  <div className="col-7 ps-4">
                    <div className="d-flex align-items-center">
                      <Redio
                        changed={(e) => setAlternateShow(e.target.value)}
                        className={""}
                        name={"alternate-appOpen"}
                        id={"alternate-show-app"}
                        isSelected={alternateShow == "true"}
                        label="Show"
                        value={"true"}
                      />
                      <Redio
                        changed={(e) => setAlternateShow(e.target.value)}
                        name={"alternate-appOpen"}
                        id={"alternate-hide-app"}
                        isSelected={alternateShow == "false"}
                        label="Hide"
                        value={"false"}
                      />
                    </div>
                  </div>
                  <div className="col-5 mb-3 d-flex justify-content-between">
                    <div className="title">Inter loading </div>
                    <div>:</div>
                  </div>
                  <div className="col-7 ps-4">
                    <div className="d-flex align-items-center">
                      <Redio
                        changed={(e) => setInterData(e.target.value)}
                        className={""}
                        name={"show-loader"}
                        id={"show-loader-inter-show"}
                        isSelected={interData == "true"}
                        label="Preload"
                        value={"true"}
                      />
                      <Redio
                        changed={(e) => setInterData(e.target.value)}
                        name={"show-loader"}
                        id={"show-loader-inter-hide"}
                        isSelected={interData == "false"}
                        label="Onload"
                        value={"false"}
                      />
                    </div>
                  </div>
                  <div className="col-5 mb-3 d-flex justify-content-between">
                    <div className="title">Inter Interval(0==hide)</div>
                    <div>:</div>
                  </div>
                  <div className="col-7 ps-4">
                    <div className="d-flex">
                      <input
                        type="number"
                        value={interInterval}
                        onChange={(e) => setInterInterval(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-5 mb-3 d-flex justify-content-between">
                    <div className="title">Black Click Inter (0==hide) </div>
                    <div>:</div>
                  </div>
                  <div className="col-7 ps-4">
                    <div className="d-flex">
                      <input
                        type="number"
                        value={blackClick}
                        onChange={(e) => setBlackClick(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="native-container">
              <div className="main-title">App Open</div>
              <div className="row align-items-center  admin-app-color-section mt-2">
                <div className="col-3 d-flex justify-content-between">
                  <div className="title">App Loading</div>
                  <div>:</div>
                </div>
                <div className="col-9 ps-4">
                  <div className="d-flex align-items-center">
                    <Redio
                      changed={(e) => setAppOpenLoading(e.target.value)}
                      // className={"    "}
                      name="app-open-loading"
                      id={"app-open-show"}
                      isSelected={appOpenLoading == "true"}
                      label="Preload"
                      value={"true"}
                    />
                    <Redio
                      changed={(e) => setAppOpenLoading(e.target.value)}
                      // className={"    "}
                      name="app-open-loading"
                      id={"app-open-hide"}
                      isSelected={appOpenLoading == "false"}
                      label="Onload"
                      value={"false"}
                    />
                  </div>
                </div>

                <div className="col-3 d-flex justify-content-between">
                  <div className="title">Splash Ads</div>
                  <div>:</div>
                </div>
                <div className="col-9 ps-4">
                  <div className="d-flex align-items-center">
                    <Redio
                      changed={(e) => setSpleshAddData(e.target.value)}
                      // className={"    "}
                      name="splesh"
                      id={"bottom-Inter"}
                      isSelected={spleshAddData == "Inter"}
                      label="Inter"
                      value={"Inter"}
                    />
                    <Redio
                      changed={(e) => setSpleshAddData(e.target.value)}
                      // className={"    "}
                      name="splesh"
                      id={"Open-Adds"}
                      isSelected={spleshAddData == "OpenAds"}
                      label="OpenAds"
                      value={"OpenAds"}
                    />
                    <Redio
                      changed={(e) => setSpleshAddData(e.target.value)}
                      // className={"    "}
                      name="splesh"
                      id={"splesh-hide"}
                      isSelected={spleshAddData == "splesh-hide"}
                      label="Hide"
                      value={"splesh-hide"}
                    />
                  </div>
                </div>
                <div className="col-3 d-flex justify-content-between">
                  <div className="title">App Open</div>
                  <div>:</div>
                </div>
                <div className="col-9 ps-4">
                  <div className="d-flex align-items-center">
                    <Redio
                      changed={(e) => setAppOpenData(e.target.value)}
                      // className={"    "}
                      name="app-open"
                      id={"one-time"}
                      isSelected={appOpenData == "One Time"}
                      label="One Time"
                      value={"One Time"}
                    />
                    <Redio
                      changed={(e) => setAppOpenData(e.target.value)}
                      // className={"    "}
                      name="app-open"
                      id={"every-time"}
                      isSelected={appOpenData == "Every Time"}
                      label="Every Time"
                      value={"Every Time"}
                    />
                    <Redio
                      changed={(e) => setAppOpenData(e.target.value)}
                      // className={"    "}
                      name="app-open"
                      id={"background-hide"}
                      isSelected={appOpenData == "Background Hide"}
                      label="Background Hide"
                      value={"Background Hide"}
                    />
                    <Redio
                      changed={(e) => setAppOpenData(e.target.value)}
                      // className={"    "}
                      name="app-open"
                      id={"app-open-hide"}
                      isSelected={appOpenData == "hide"}
                      label="Hide"
                      value={"hide"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Other Setting */}

          <div className="other-container mt-4 ms-2">
            <div className="heading-other-setting">
              <h3>Other Setting</h3>
            </div>
            <div className="row">
              <div className="col-lg-5">
                <div className="d-flex justify-content-between">
                  <p> All Ads</p>
                  <div>:</div>
                </div>
              </div>
              <div className="col-lg-7 d-flex">
                <Redio
                  changed={handleAllAdsRadio}
                  className={""}
                  name={"all-ads"}
                  id={"all-ads-show"}
                  isSelected={allAds == "true"}
                  label="Show"
                  value={"true"}
                />
                <Redio
                  changed={handleAllAdsRadio}
                  className={""}
                  name={"all-ads"}
                  id={"all-ads-hide"}
                  isSelected={allAds == "false"}
                  label="Hide"
                  value={"false"}
                />
              </div>

              {/* } */}

              <div className="col-lg-5 ">
                <div className=" d-flex justify-content-between">
                  <p> Full Screeen (Navigation)</p>
                  <div>:</div>
                </div>
              </div>
              <div className="col-lg-7 d-flex">
                <Redio
                  changed={handleScreenNavigation}
                  className={""}
                  name={"screen-navigation-a"}
                  id={"navigation-show"}
                  isSelected={screenNavigationFull == "true"}
                  label="Show"
                  value={"true"}
                />
                <Redio
                  changed={handleScreenNavigation}
                  className={""}
                  name={"screen-navigation-a"}
                  id={"navigation-hide"}
                  isSelected={screenNavigationFull == "false"}
                  label="Hide"
                  value={"false"}
                />
              </div>

              <div className="col-lg-5">
                <div className="d-flex justify-content-between">
                  <p> VersionCode for Add Block</p>
                  <div>:</div>
                </div>
              </div>
              <div className="col-lg-7">
                <div>
                  <input
                    type="text"
                    name="add-block"
                    value={addBlock}
                    id="add-block"
                    onChange={(e) => setAddBlock(e.target.value)}
                  />
                </div>
              </div>

              <div className="col-lg-5">
                <div className=" d-flex justify-content-between">
                  <p> Continue Screen</p>
                  <div>:</div>
                </div>
              </div>
              <div className="col-lg-7 d-flex">
                <Redio
                  changed={handleContinueScreen}
                  className={""}
                  name={"continue-screen"}
                  id={"continue-screen-show"}
                  isSelected={continueScreen == "true"}
                  label="Show"
                  value={"true"}
                />
                <Redio
                  changed={handleContinueScreen}
                  className={""}
                  name={"continue-screen"}
                  id={"continue-screen-hide"}
                  isSelected={continueScreen == "false"}
                  label="Hide"
                  value={"false"}
                />
              </div>

              <div className="col-lg-5">
                <div className=" d-flex justify-content-between">
                  <p> Let's Start Screen</p>
                  <div>:</div>
                </div>
              </div>
              <div className="col-lg-7 d-flex">
                <Redio
                  changed={handleLetsStartScreen}
                  className={""}
                  name={"start-screen"}
                  id={"start-screen-show"}
                  isSelected={startScreen == "true"}
                  label="Show"
                  value={"true"}
                />
                <Redio
                  changed={handleLetsStartScreen}
                  className={""}
                  name={"start-screen"}
                  id={"start-screen-hide"}
                  isSelected={startScreen == "false"}
                  label="Hide"
                  value={"false"}
                />
              </div>

              <div className="col-lg-5">
                <div className=" d-flex justify-content-between">
                  <p> Age/Gender Start Screen</p>
                  <div>:</div>
                </div>
              </div>
              <div className="col-lg-7 d-flex">
                <Redio
                  changed={handleGenderScreen}
                  className={""}
                  name={"gender-screen"}
                  id={"gender-screen-show"}
                  isSelected={genderScreen == "true"}
                  label="Show"
                  value={"true"}
                />
                <Redio
                  changed={handleGenderScreen}
                  className={""}
                  name={"gender-screen"}
                  id={"gender-screen-hide"}
                  isSelected={genderScreen == "false"}
                  label="Hide"
                  value={"false"}
                />
              </div>

              <div className="col-lg-5">
                <div className=" d-flex justify-content-between">
                  <p> Next Screen</p>
                  <div>:</div>
                </div>
              </div>
              <div className="col-lg-7 d-flex">
                <Redio
                  changed={handleNextScreen}
                  className={""}
                  name={"next-screen"}
                  id={"next-screen-show"}
                  isSelected={nextScreen == "true"}
                  label="Show"
                  value={"true"}
                />
                <Redio
                  changed={handleNextScreen}
                  className={""}
                  name={"next-screen"}
                  id={"next-screen-hide"}
                  isSelected={nextScreen == "false"}
                  label="Hide"
                  value={"false"}
                />
              </div>

              <div className="col-lg-5">
                <div className=" d-flex justify-content-between">
                  <p> Next Inner Screen</p>
                  <div>:</div>
                </div>
              </div>
              <div className="col-lg-7 d-flex">
                <Redio
                  changed={handleNextInnerScreen}
                  className={""}
                  name={"next-inner-screen"}
                  id={"next-inner-screen-show"}
                  isSelected={nextInnerScreen == "true"}
                  label="Show"
                  value={"true"}
                />
                <Redio
                  changed={handleNextInnerScreen}
                  className={""}
                  name={"next-inner-screen"}
                  id={"next-inner-screen-hide"}
                  isSelected={nextInnerScreen == "false"}
                  label="Hide"
                  value={"false"}
                />
              </div>

              <div className="col-lg-5">
                <div className=" d-flex justify-content-between">
                  <p> Connect Screen</p>
                  <div>:</div>
                </div>
              </div>
              <div className="col-lg-7 d-flex">
                <Redio
                  changed={handleConnectScreen}
                  className={""}
                  name={"connect-screen"}
                  id={"connect-screen-show"}
                  isSelected={connectScreen == "true"}
                  label="Show"
                  value={"true"}
                />
                <Redio
                  changed={handleConnectScreen}
                  className={""}
                  name={"connect-screen"}
                  id={"connect-screen-hide"}
                  isSelected={connectScreen == "false"}
                  label="Hide"
                  value={"false"}
                />
              </div>

              <div className="col-lg-5">
                <div className=" d-flex justify-content-between">
                  <p>Start Screen</p>
                  <div>:</div>
                </div>
              </div>
              <div className="col-lg-7 d-flex">
                <Redio
                  changed={handleStartScreen}
                  className={""}
                  name={"start-again-screen"}
                  id={"start-again-screen-show"}
                  isSelected={startAgainScreen == "true"}
                  label="Show"
                  value={"true"}
                />
                <Redio
                  changed={handleStartScreen}
                  className={""}
                  name={"start-again-screen"}
                  id={"start-again-screen-hide"}
                  isSelected={startAgainScreen == "false"}
                  label="Hide"
                  value={"false"}
                />
              </div>

              <div className="col-lg-5">
                <div className=" d-flex justify-content-between">
                  <p>Real Casting Flow</p>
                  <div>:</div>
                </div>
              </div>
              <div className="col-lg-7 d-flex">
                <Redio
                  changed={handleCastinFlow}
                  className={""}
                  name={"casting-flow"}
                  id={"casting-flow-show"}
                  isSelected={castingFlow == "true"}
                  label="Show"
                  value={"true"}
                />
                <Redio
                  changed={handleCastinFlow}
                  className={""}
                  name={"casting-flow"}
                  id={"casting-flow-hide"}
                  isSelected={castingFlow == "false"}
                  label="Hide"
                  value={"false"}
                />
              </div>

              <div className="col-lg-5">
                <div className=" d-flex justify-content-between">
                  <p>Dialog For App Stop</p>
                  <div>:</div>
                </div>
              </div>
              <div className="col-lg-7 d-flex">
                <Redio
                  changed={handleDialogApp}
                  className={""}
                  name={"dialoge-app"}
                  id={"dialoge-app-show"}
                  isSelected={dialogApp == "true"}
                  label="Show"
                  value={"true"}
                />
                <Redio
                  changed={handleDialogApp}
                  className={""}
                  name={"dialoge-app"}
                  id={"dialoge-app-hide"}
                  isSelected={dialogApp == "false"}
                  label="Hide"
                  value={"false"}
                />
              </div>

              {additionalFields?.map((field, index) => (
                <>
                  <div className="col-lg-5">
                    <div className=" d-flex justify-content-between">
                      <p>{field.name}</p>
                      <div>:</div>
                    </div>
                  </div>
                  {field.type == "textbox" && (
                    <div className="col-lg-7 d-flex">
                      <input
                        type="text"
                        name={`additional-field-${index}`}
                        value={field.value}
                        id={`additional-field-${index}`}
                        onChange={(e) => {
                          field.value = e.target.value;
                          setAdditionalFields([...additionalFields]);
                        }}
                      />
                    </div>
                  )}
                  {field.type == "radio" && (
                    <div className="col-lg-7 d-flex">
                      <Redio
                        changed={(e) => {
                          field.value = e.target.value;
                          setAdditionalFields([...additionalFields]);
                        }}
                        className={""}
                        name={`additional-field-${index}`}
                        id={`additional-field-show${index}`}
                        isSelected={field.value == "true"}
                        label="Show"
                        value={"true"}
                      />
                      <Redio
                        changed={(e) => {
                          field.value = e.target.value;
                          setAdditionalFields([...additionalFields]);
                        }}
                        className={""}
                        name={`additional-field-${index}`}
                        id={`additional-field-hide-${index}`}
                        isSelected={field.value == "false"}
                        label="Hide"
                        value={"false"}
                      />
                    </div>
                  )}
                  {field.type == "radio-textbox" && (
                    <div className="col-lg-7 d-flex">
                      <Redio
                        changed={(e) => {
                          field.enabled = e.target.value;
                          setAdditionalFields([...additionalFields]);
                        }}
                        className={""}
                        name={`additional-field-${index}`}
                        id={`additional-field-show-${index}`}
                        isSelected={field.enabled == "true"}
                        label="Show"
                        value={"true"}
                      />
                      <Redio
                        changed={(e) => {
                          field.enabled = e.target.value;
                          setAdditionalFields([...additionalFields]);
                        }}
                        className={""}
                        name={`additional-field-${index}`}
                        id={`additional-field-hide-${index}`}
                        isSelected={field.enabled == "false"}
                        label="Hide"
                        value={"false"}
                      />
                      <input
                        type="text"
                        name={`additional-field-textbox-${index}`}
                        value={field.value}
                        id={`additional-field-${index}`}
                        onChange={(e) => {
                          field.value = e.target.value;
                          setAdditionalFields([...additionalFields]);
                        }}
                        disabled={field.enabled == "true" ? false : true}
                      />
                    </div>
                  )}
                </>
              ))}
            </div>
          </div>

          <AddButton
            buttonName={"Add Field"}
            buttonStyle={"other-add-field"}
            onClick={handleShow}
          />

          {/* Vpn setting */}
          <div className="vpn-container">
            <div className="vpn-heading">
              <h3>VPN Setting</h3>
            </div>
            <form className="vpn-organic-form">
              <div className="row">
                <div className="col-lg-6 d-flex justify-content-between">
                  <p> VPN</p>
                  <div>:</div>
                </div>
                <div className="col-lg-6 d-flex">
                  <Redio
                    changed={handleVpn}
                    className={""}
                    name={"screen-show"}
                    id={"screen-show"}
                    isSelected={vpn == "true"}
                    label="Show"
                    value={"true"}
                  />
                  <Redio
                    changed={handleVpn}
                    className={""}
                    name={"screen-show"}
                    id={"screen-hide"}
                    isSelected={vpn == "false"}
                    label="Hide"
                    value={"false"}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 d-flex justify-content-between">
                  <p> VPN Dialog</p>
                  <div>:</div>
                </div>
                <div className="col-lg-6 d-flex">
                  <Redio
                    changed={handleVpmDialog}
                    className={""}
                    name={"VPN-Dialog-show"}
                    id={"VPN-Dialog"}
                    isSelected={screenVpnDialog == "true"}
                    label="Show"
                    value={"true"}
                  />
                  <Redio
                    changed={handleVpmDialog}
                    className={""}
                    name={"VPN-Dialog-show"}
                    id={"VPN-Dialog-hide"}
                    isSelected={screenVpnDialog == "false"}
                    label="Hide"
                    value={"false"}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 d-flex justify-content-between">
                  <p> VPN Dialog Open</p>
                  <div>:</div>
                </div>
                <div className="col-lg-6 d-flex">
                  <Redio
                    changed={handleVpmDialogOpen}
                    className={""}
                    name={"VPN-Dialog-open-show"}
                    id={"VPN-Dialog-open"}
                    isSelected={vpmDialogOpen == "true"}
                    label="Show"
                    value={"true"}
                  />
                  <Redio
                    changed={handleVpmDialogOpen}
                    className={""}
                    name={"VPN-Dialog-open-show"}
                    id={"VPN-Dialog-open-hide"}
                    isSelected={vpmDialogOpen == "false"}
                    label="Hide"
                    value={"false"}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 d-flex justify-content-between">
                  <p> VPN List Country</p>
                  <div>:</div>
                </div>
                <div className="col-lg-6">
                  <TagsInput
                    value={VpnListCountry}
                    onChange={setVpnListCountry}
                    name="vpn-country"
                  />
                </div>
              </div>
              <div className="row py-2">
                <div className="col-lg-6 d-flex justify-content-between ">
                  <label htmlFor="vpnUrl">VPN url</label>
                  <div>:</div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group d-flex  ">
                    <input
                      type="text"
                      name="vpnUrl"
                      value={vpnOrganicData.vpnUrl}
                      className="form-control"
                      id="vpnUrl"
                      // placeholder="Enter Server Number"
                      onChange={handleVpnOrganicInput}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 d-flex justify-content-between">
                  <label htmlFor="vpnId">VPN Carrier Id</label>
                  <div>:</div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group d-flex  ">
                    <input
                      type="text"
                      name="vpnId"
                      value={vpnOrganicData.vpnId}
                      className="form-control"
                      id="vpnId"
                      // placeholder="Enter Server Number"
                      onChange={handleVpnOrganicInput}
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>

          <AddButton onClick={handleAddSettingOrganic} buttonName={"Update"} />
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>New Field</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Label>Field Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="field name"
              value={newFieldName}
              onChange={(e) => setNewFieldName(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Type</Form.Label>
            <Redio
              changed={(e) => {
                setNewFieldType(e.target.value);
              }}
              className={""}
              name={`additional-field-type`}
              id={`additional-field-type-radio`}
              isSelected={newFieldType == "radio"}
              label="Radio"
              value={"radio"}
            />
            <Redio
              changed={(e) => {
                setNewFieldType(e.target.value);
              }}
              className={""}
              name={`additional-field-type`}
              id={`additional-field-type-textbox`}
              isSelected={newFieldType == "textbox"}
              label="Textbox"
              value={"textbox"}
            />
            <Redio
              changed={(e) => {
                setNewFieldType(e.target.value);
              }}
              className={""}
              name={`additional-field-type`}
              id={`additional-field-type-radio-textbox`}
              isSelected={newFieldType == "radio-textbox"}
              label="Radio + Textbox"
              value={"radio-textbox"}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleAddField}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default BifurcateOrganic;
