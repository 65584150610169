import { faArrowAltCircleLeft } from '@fortawesome/free-regular-svg-icons';
import { faBackward, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Accordion } from 'react-bootstrap';
import { Link, NavLink, useLocation, useSearchParams } from 'react-router-dom'

const EditUserSideBar = () => {
    // const location = useLocation()
    // console.log("location:::::::::::", location.pathname)
    const [searchParams] = useSearchParams();

    return (
        <>
            <div className='editUser-sidebar w-100'>
                <div className="sidebar p-4">
                    <div className="top_section">
                        <div className='editUser-sidebar-component w-100'>
                            <div>
                                {/* faBackward */}
                                <Link to="/apps" state={{ appTab: searchParams.get("appTab") }} ><span className='pe-2'><FontAwesomeIcon icon={faArrowAltCircleLeft} style={{ color: "#000000", width: "25px", height: "25px" }} /></span></Link>
                            </div>
                            <div className='user-menu' >
                                <Link to="/user-setting"> <span className='pe-2'><FontAwesomeIcon icon={faUser} /></span>User</Link>
                            </div>
                            <div className='user-menu' >
                                <Link to="/retantion"><span className='pe-2'><FontAwesomeIcon icon={faUser} /></span>Retantion</Link>
                            </div>
                            {/* accordion */}
                            <div className='setting-user-accordion mt-3'>
                                <Accordion>
                                    <Accordion.Item eventKey="0">

                                        <Accordion.Header>Setting(O)</Accordion.Header>
                                        <Accordion.Body>
                                            <div className='edit-nav-menu mb-4'>
                                                <NavLink to="/google-organic" className="edit-user-navlink">Google</NavLink>
                                            </div>
                                        </Accordion.Body>
                                        <Accordion.Body>
                                            <div className='edit-nav-menu mb-4'>
                                                <NavLink to="/setting" className="edit-user-navlink">Add Setting</NavLink>
                                            </div>
                                        </Accordion.Body>
                                        <Accordion.Body>
                                            <div className='edit-nav-menu mb-4'>
                                                <NavLink to="/bifurcate-organic" className="edit-user-navlink">Bifurcate</NavLink>
                                            </div>
                                        </Accordion.Body>
                                        <Accordion.Body>
                                            <div className='edit-nav-menu mb-4'>
                                                <NavLink to="/other-setting" className="edit-user-navlink">Other</NavLink>
                                            </div>
                                        </Accordion.Body>
                                        <Accordion.Body>
                                            <div className='edit-nav-menu mb-4'>
                                                <NavLink to="/vpn-organic" className="edit-user-navlink">VPN</NavLink>
                                            </div>
                                        </Accordion.Body>
                                        <Accordion.Body>
                                            <div className='edit-nav-menu mb-4'>
                                                <NavLink to="/app-remove-flag" className="edit-user-navlink">App Remove Flags</NavLink>
                                            </div>
                                        </Accordion.Body>

                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1">

                                        <Accordion.Header>Setting(M)</Accordion.Header>
                                        <Accordion.Body>
                                            <div className='edit-nav-menu mb-4'>
                                                <NavLink to="/google-m" className="edit-user-navlink">Google</NavLink>
                                            </div>
                                        </Accordion.Body>
                                        <Accordion.Body>
                                            <div className='edit-nav-menu mb-4'>
                                                <NavLink to="/setting-m" className="edit-user-navlink">Add Setting</NavLink>
                                            </div>
                                        </Accordion.Body>
                                        <Accordion.Body>
                                            <div className='edit-nav-menu mb-4'>
                                                <NavLink to="/bifurcate-m" className="edit-user-navlink">Bifurcate</NavLink>
                                            </div>
                                        </Accordion.Body>
                                        <Accordion.Body>
                                            <div className='edit-nav-menu mb-4'>
                                                <NavLink to="/other-m" className="edit-user-navlink">Other</NavLink>
                                            </div>
                                        </Accordion.Body>
                                        <Accordion.Body>
                                            <div className='edit-nav-menu mb-4'>
                                                <NavLink to="/vpn-m" className="edit-user-navlink">VPN</NavLink>
                                            </div>
                                        </Accordion.Body>
                                        <Accordion.Body>
                                            <div className='edit-nav-menu mb-4'>
                                                <NavLink to="/app-remove-flag-m" className="edit-user-navlink">App Remove Flags</NavLink>
                                            </div>
                                        </Accordion.Body>

                                    </Accordion.Item>

                                </Accordion>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>

    )
}

export default EditUserSideBar