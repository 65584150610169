import React, { useEffect } from 'react';
import "../Css/GoogleSettingOrganic.css"
import EditUserSideBar from '../Components/Sidebar/EditUserSideBar';
import { useState } from 'react';
import AddButton from '../Components/Button/AddButton';
import apiServices from '../services/apiServices';
import { useLocation, useNavigate } from 'react-router';
import { NotificationContainer, NotificationManager } from 'react-notifications';

import Header from '../Components/Header/Header';



const GoogleSettingOrganic = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const [localStorageData, setLocalStorageData] = useState({})

    const [GoogleOrganicData, setGoogleOrganicData] = useState({
        percentageOne: "",
        google_account_name: '',
        google_banner: 'ca-app-pub-3940256099942544/630097811',
        google_inter: 'ca-app-pub-3940256099942544/1033173712',
        google_native: 'ca-app-pub-3940256099942544/2247696110',
        google_native2: 'ca-app-pub-3940256099942544/2247696110',
        google_appOpen: 'ca-app-pub-3940256099942544/3419835294',
        google_appId: ''
    });
    const [Google2Data, setGoogle2Data] = useState({
        percentageTwo: "",
        google_account_name: '',
        google_banner: 'ca-app-pub-3940256099942544/630097811',
        google_inter: 'ca-app-pub-3940256099942544/1033173712',
        google_native: 'ca-app-pub-3940256099942544/2247696110',
        google_native2: 'ca-app-pub-3940256099942544/2247696110',
        google_appOpen: 'ca-app-pub-3940256099942544/3419835294',
        google_appId: ''
    })
    const [Google3Data, setGoogle3Data] = useState({
        google_account_name: '',
        google_banner: 'ca-app-pub-3940256099942544/630097811',
        google_inter: 'ca-app-pub-3940256099942544/1033173712',
        google_native: 'ca-app-pub-3940256099942544/2247696110',
        google_native2: 'ca-app-pub-3940256099942544/2247696110',
        google_appOpen: 'ca-app-pub-3940256099942544/3419835294',
        google_appId: ''
    })
    const createNotification = () => {
        NotificationManager.success('Record updated successfully');
    }
    console.log("Google2Data", Google2Data)
    console.log("GoogleOrganicData", GoogleOrganicData)

    useEffect(() => {
        if (localStorage.getItem("id")) {
            if (localStorage.getItem("appAllData")) {
                const data = JSON.parse(localStorage.getItem("appAllData"))
                setLocalStorageData(data)
                if (data.organic.google != null) {
                    if (data.addApp.status == "status") {
                        setGoogleOrganicData({
                            percentageOne: "",
                            google_account_name: '',
                            google_banner: 'ca-app-pub-3940256099942544/630097811',
                            google_inter: 'ca-app-pub-3940256099942544/1033173712',
                            google_native: 'ca-app-pub-3940256099942544/2247696110',
                            google_native2: 'ca-app-pub-3940256099942544/2247696110',
                            google_appOpen: 'ca-app-pub-3940256099942544/3419835294',
                            google_appId: ''
                        })
                        setGoogle2Data({
                            percentageTwo: "",
                            google_account_name: '',
                            google_banner: 'ca-app-pub-3940256099942544/630097811',
                            google_inter: 'ca-app-pub-3940256099942544/1033173712',
                            google_native: 'ca-app-pub-3940256099942544/2247696110',
                            google_native2: 'ca-app-pub-3940256099942544/2247696110',
                            google_appOpen: 'ca-app-pub-3940256099942544/3419835294',
                            google_appId: ''
                        })
                        setGoogle3Data({
                            google_account_name: '',
                            google_banner: 'ca-app-pub-3940256099942544/630097811',
                            google_inter: 'ca-app-pub-3940256099942544/1033173712',
                            google_native: 'ca-app-pub-3940256099942544/2247696110',
                            google_native2: 'ca-app-pub-3940256099942544/2247696110',
                            google_appOpen: 'ca-app-pub-3940256099942544/3419835294',
                            google_appId: ''
                        })
                    } else {
                        setGoogleOrganicData({
                            percentageOne: data.organic.google.google1.percentageOne,
                            google_account_name: data.organic.google.google1.google_account_name,
                            google_banner: data.organic.google.google1.google_banner,
                            google_inter: data.organic.google.google1.google_inter,
                            google_native: data.organic.google.google1.google_native,
                            google_native2: data.organic.google.google1.google_native2,
                            google_appOpen: data.organic.google.google1.google_appOpen,
                            google_appId: data.organic.google.google1.google_appId,
                        })
                        setGoogle2Data({
                            percentageTwo: data.organic.google.google2.percentageTwo,
                            google_account_name: data.organic.google.google2.google_account_name,
                            google_banner: data.organic.google.google2.google_banner,
                            google_inter: data.organic.google.google2.google_inter,
                            google_native: data.organic.google.google2.google_native,
                            google_native2: data.organic.google.google2.google_native2,
                            google_appOpen: data.organic.google.google2.google_appOpen,
                            google_appId: data.organic.google.google2.google_appId,
                        })
                        setGoogle3Data({
                            google_account_name: data.organic.google.google3.google_account_name,
                            google_banner: data.organic.google.google3.google_banner,
                            google_inter: data.organic.google.google3.google_inter,
                            google_native: data.organic.google.google3.google_native,
                            google_native2: data.organic.google.google3.google_native2,
                            google_appOpen: data.organic.google.google3.google_appOpen,
                            google_appId: data.organic.google.google3.google_appId,
                        })
                    }
                }
            }
        } else {
            navigate("/apps")
        }
    }, [])

    const handleFirstGoogleData = (e) => {
        const { name, value } = e.target;

        if (e.target.name == "percentageOne") {
            setGoogle2Data((prevFormData) => ({
                ...prevFormData,
                percentageTwo: (100 - e.target.value).toString(),
            }))
        }
        setGoogleOrganicData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
        // console.log("FirstGoogleSettingInput+++++++", value)
    };

    const handleTestIdAdd = () => {
        setGoogleOrganicData({
            // percentageOne: localStorageData.organic.google.google1.percentageOne,
            google_account_name: "",
            google_banner: 'ca-app-pub-3940256099942544/630097811',
            google_inter: 'ca-app-pub-3940256099942544/1033173712',
            google_native: 'ca-app-pub-3940256099942544/2247696110',
            google_native2: 'ca-app-pub-3940256099942544/2247696110',
            google_appOpen: 'ca-app-pub-3940256099942544/3419835294',
            google_appId: ""

        })
        setGoogle2Data({
            // percentageTwo: "",
            google_account_name: "",
            google_banner: 'ca-app-pub-3940256099942544/630097811',
            google_inter: 'ca-app-pub-3940256099942544/1033173712',
            google_native: 'ca-app-pub-3940256099942544/2247696110',
            google_native2: 'ca-app-pub-3940256099942544/2247696110',
            google_appOpen: 'ca-app-pub-3940256099942544/3419835294',
            google_appId: ""
        })
        setGoogle3Data({
            google_account_name: "",
            google_banner: 'ca-app-pub-3940256099942544/630097811',
            google_inter: 'ca-app-pub-3940256099942544/1033173712',
            google_native: 'ca-app-pub-3940256099942544/2247696110',
            google_native2: 'ca-app-pub-3940256099942544/2247696110',
            google_appOpen: 'ca-app-pub-3940256099942544/3419835294',
            google_appId: ""
        })

    }

    const handleTestIdRemove = () => {
        setGoogleOrganicData({
            // percentageOne: localStorageData.organic.google.google1.percentageOne,
            google_banner: "",
            google_inter: "",
            google_native: "",
            google_native2: "",
            google_appOpen: "",
            google_appId: "",
            google_account_name: ""

        })
        setGoogle2Data({
            // percentageTwo: "",
            google_account_name: "",
            google_banner: "",
            google_inter: "",
            google_native: "",
            google_native2: "",
            google_appOpen: "",
            google_appId: "",

        })
        setGoogle3Data({
            google_banner: "",
            google_inter: "",
            google_native: "",
            google_native2: "",
            google_appOpen: "",
            google_appId: "",
            google_account_name: "",
            google_appId: ""

        })
    }

    const handleSecondGoogleData = (e) => {
        const { name, value } = e.target;

        setGoogle2Data((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
        // console.log("SecondGoogleSettingInput+++++++", value)
    };

    const handleThirdGoogleData = (e) => {
        const { name, value } = e.target;
        setGoogle3Data((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
        // console.log("ThirdGoogleSettingInput+++++++", value)
    };
    const handleGoogleOrganic = async () => {
        const organicData = localStorageData
        const google = {
            google1: GoogleOrganicData,
            google2: Google2Data,
            google3: Google3Data
        }
        organicData["organic"] = { ...organicData["organic"], google }
        const res = await apiServices.updateApp(organicData, localStorage.getItem("id"), organicData.console_id);
        console.log("ResOrganicGoogle::::::", res)
        if (res) {
            localStorage.setItem("appAllData", JSON.stringify(res.AppData))
            // console.log("resOrganic", res)
            createNotification('success')
        }
    }
    return (
        <>
            <Header />
            {localStorage.getItem("token")
                &&
                <div className="col-lg-2">
                    <EditUserSideBar />
                </div>
            }
            <NotificationContainer />

            <div className={`${localStorage.getItem("token") ? "col-lg-10" : "col-lg-12"}`}>

                {/* <h1> {localStorageData?.addApp?.app_name}</h1> */}
                <div className='google-o-conatiner'>
                    <div className='google-setting-heading'>
                        <h1>Google</h1>
                    </div>
                    <div className='row'>
                        <div className='col-lg-4 border-end google-setting-sub-heading'>
                            <h4>Google1</h4>
                            <div className="form-group d-flex mb-3">
                                <label className='google-label col-4' htmlFor="accountName1">Google1(%):</label>
                                <div className='col-8'>
                                    <input
                                        className="form-control"
                                        id="accountName1"
                                        type="text"
                                        name="percentageOne"
                                        value={GoogleOrganicData.percentageOne}
                                        onChange={handleFirstGoogleData}
                                    />
                                </div>
                            </div>
                            <div className="form-group d-flex mb-3">
                                <label className='google-label col-4' htmlFor="accountName1">Google1 AccountName:</label>
                                <div className='col-8'>
                                    <input
                                        className="form-control"
                                        id="accountName1"
                                        type="text"
                                        name="google_account_name"
                                        value={GoogleOrganicData.google_account_name}
                                        onChange={handleFirstGoogleData}
                                    />
                                </div>
                            </div>
                            <div className="form-group d-flex mb-3">
                                <label className='google-label col-4' htmlFor="googleBanner1">Google1 Banner:</label>
                                <div className='col-8'>
                                    <input
                                        className="form-control"
                                        id="googleBanner1"
                                        type="text"
                                        name="google_banner"
                                        value={GoogleOrganicData.google_banner}
                                        onChange={handleFirstGoogleData}
                                    />
                                </div>
                            </div>
                            <div className="form-group d-flex mb-3">
                                <label className='google-label col-4' htmlFor="googleInter1">Google1 Inter:</label>
                                <div className='col-8' >

                                    <input
                                        className="form-control"
                                        id="googleInter1"
                                        type="text"
                                        name="google_inter"
                                        value={GoogleOrganicData.google_inter}
                                        onChange={handleFirstGoogleData}
                                    />
                                </div>
                            </div>
                            <div className="form-group d-flex mb-3">
                                <label className='google-label col-4' htmlFor="googleNative1">Google1 Native:</label>
                                <div className='col-8' >
                                    <input
                                        className="form-control"
                                        id="googleNative1"
                                        type="text"
                                        name="google_native"
                                        value={GoogleOrganicData.google_native}
                                        onChange={handleFirstGoogleData}
                                    />
                                </div>
                            </div>
                            <div className="form-group d-flex mb-3">
                                <label className='google-label col-4' htmlFor="googleNative2">Google1 Native2:</label>
                                <div className='col-8'>
                                    <input
                                        className="form-control"
                                        type="text"
                                        id="googleNative2"
                                        name="google_native2"
                                        value={GoogleOrganicData.google_native2}
                                        onChange={handleFirstGoogleData}

                                    />
                                </div>
                            </div>
                            <div className="form-group d-flex mb-3">
                                <label className='google-label col-4' htmlFor="googleAppOpen1">Google1 AppOpen:</label>
                                <div className='col-8'>
                                    <input
                                        className="form-control"
                                        id="googleAppOpen1"
                                        type="text"
                                        name="google_appOpen"
                                        value={GoogleOrganicData.google_appOpen}
                                        onChange={handleFirstGoogleData}

                                    />
                                </div>
                            </div>
                            <div className="form-group d-flex mb-3">
                                <label className='google-label col-4' htmlFor="googleAppId1">Google1 AppId:</label>
                                <div className='col-8'>
                                    <input
                                        className="form-control"
                                        id="googleAppId1"
                                        type="text"
                                        name="google_appId"
                                        value={GoogleOrganicData.google_appId}
                                        onChange={handleFirstGoogleData}
                                    />
                                </div>
                            </div>
                        </div>

                        {/* Google2 */}

                        <div className='col-lg-4  border-end google-setting-sub-heading'>
                            <h4>Google2</h4>
                            <div className="form-group d-flex mb-3">
                                <label className='google-label col-4' htmlFor="accountName1">Google2(%):</label>
                                <div className='col-8'>
                                    <input
                                        className="form-control"
                                        id="accountName1"
                                        type="text"
                                        name="percentageTwo"
                                        value={Google2Data.percentageTwo}
                                        onChange={handleSecondGoogleData}
                                    />
                                </div>
                            </div>
                            <div className="form-group d-flex mb-3">
                                <label className='google-label col-4' htmlFor="accountName2">Google2 AccountName:</label>
                                <div className='col-8'>
                                    <input
                                        className="form-control"
                                        id="accountName2"
                                        type="text"
                                        name="google_account_name"
                                        value={Google2Data.google_account_name}
                                        onChange={handleSecondGoogleData}

                                    />
                                </div>
                            </div>
                            <div className="form-group d-flex mb-3">
                                <label className='google-label col-4' htmlFor="googleBanner2">Google2 Banner:</label>
                                <div className='col-8'>
                                    <input
                                        className="form-control"
                                        id="googleBanner2"
                                        type="text"
                                        name="google_banner"
                                        value={Google2Data.google_banner}
                                        onChange={handleSecondGoogleData}
                                    />
                                </div>
                            </div>
                            <div className="form-group d-flex mb-3">
                                <label className='google-label col-4' htmlFor="googleInter2">Google2 Inter:</label>
                                <div className='col-8' >

                                    <input
                                        className="form-control"
                                        id="googleInter2"
                                        type="text"
                                        name="google_inter"
                                        value={Google2Data.google_inter}
                                        onChange={handleSecondGoogleData}

                                    />
                                </div>
                            </div>
                            <div className="form-group d-flex mb-3">
                                <label className='google-label col-4' htmlFor="secondGoogleNative1">Google2 Native:</label>
                                <div className='col-8' >
                                    <input

                                        className="form-control"
                                        id="secondGoogleNative1"
                                        type="text"
                                        name="google_native"
                                        value={Google2Data.google_native}
                                        onChange={handleSecondGoogleData}

                                    />
                                </div>
                            </div>
                            <div className="form-group d-flex mb-3">
                                <label className='google-label col-4' htmlFor="secondGoogleNative2">Google2 Native2:</label>
                                <div className='col-8'>
                                    <input
                                        className="form-control"
                                        id="secondGoogleNative2"
                                        type="text"
                                        name="google_native2"
                                        value={Google2Data.google_native2}
                                        onChange={handleSecondGoogleData}

                                    />
                                </div>
                            </div>
                            <div className="form-group d-flex mb-3">
                                <label className='google-label col-4' htmlFor="googleAppOpen2">Google2 AppOpen:</label>
                                <div className='col-8'>
                                    <input
                                        className="form-control"
                                        id="googleAppOpen2"
                                        type="text"
                                        name="google_appOpen"
                                        value={Google2Data.google_appOpen}
                                        onChange={handleSecondGoogleData}
                                    />
                                </div>
                            </div>
                            <div className="form-group d-flex mb-3">
                                <label className='google-label col-4' htmlFor="googleAppId2">Google2 AppId:</label>
                                <div className='col-8'>
                                    <input
                                        className="form-control"
                                        id="googleAppId2"
                                        type="text"
                                        name="google_appId"
                                        value={Google2Data.google_appId}
                                        onChange={handleSecondGoogleData}

                                    />
                                </div>
                            </div>
                        </div>

                        {/* Google3 */}
                        <div className='col-lg-4 google-setting-sub-heading'>
                            <h4>Google3</h4>

                            <div className="form-group d-flex mb-3">
                                <label className='google-label col-4' htmlFor="accountName3">Google3 AccountName:</label>
                                <div className='col-8'>
                                    <input
                                        className="form-control"
                                        id="accountName3"
                                        type="text"
                                        name="google_account_name"
                                        value={Google3Data.google_account_name}
                                        onChange={handleThirdGoogleData}

                                    />
                                </div>
                            </div>
                            <div className="form-group d-flex mb-3">
                                <label className='google-label col-4' htmlFor="googleBanner3">Google3 Banner:</label>
                                <div className='col-8'>
                                    <input
                                        className="form-control"
                                        id="googleBanner3"
                                        type="text"
                                        name="google_banner"
                                        value={Google3Data.google_banner}
                                        onChange={handleThirdGoogleData}

                                    />
                                </div>
                            </div>
                            <div className="form-group d-flex mb-3">
                                <label className='google-label col-4' htmlFor="thirdGoogleInter">Google3 Inter:</label>
                                <div className='col-8' >

                                    <input
                                        className="form-control"
                                        id="thirdGoogleInter"
                                        type="text"
                                        name="google_inter"
                                        value={Google3Data.google_inter}
                                        onChange={handleThirdGoogleData}

                                    />
                                </div>
                            </div>
                            <div className="form-group d-flex mb-3">
                                <label className='google-label col-4' htmlFor="thirdGoogleNative">Google3 Native:</label>
                                <div className='col-8' >
                                    <input
                                        className="form-control"
                                        id="thirdGoogleNative"
                                        type="text"
                                        name="google_native"
                                        value={Google3Data.google_native}
                                        onChange={handleThirdGoogleData}

                                    />
                                </div>
                            </div>
                            <div className="form-group d-flex mb-3">
                                <label className='google-label col-4' htmlFor="thirdGoogleNative3">Google3 Native2:</label>
                                <div className='col-8'>
                                    <input
                                        className="form-control"
                                        id="googleNative2"
                                        type="text"
                                        name="google_native2"
                                        value={Google3Data.google_native2}
                                        onChange={handleThirdGoogleData}

                                    />
                                </div>
                            </div>
                            <div className="form-group d-flex mb-3">
                                <label className='google-label col-4' htmlFor="googleAppOpen3">Google3 AppOpen:</label>
                                <div className='col-8'>
                                    <input
                                        className="form-control"
                                        id="googleAppOpen3"
                                        type="text"
                                        name="google_appOpen"
                                        value={Google3Data.google_appOpen}
                                        onChange={handleThirdGoogleData}

                                    />
                                </div>
                            </div>
                            <div className="form-group d-flex mb-3">
                                <label className='google-label col-4' htmlFor="googleAppId3">Google3 AppId:</label>
                                <div className='col-8'>
                                    <input
                                        className="form-control"
                                        id="googleAppId3"
                                        type="text"
                                        name="google_appId"
                                        value={Google3Data.google_appId}
                                        onChange={handleThirdGoogleData}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div className='d-flex'>
                    <AddButton onClick={handleGoogleOrganic} buttonName={"Update"} />
                    <AddButton onClick={handleTestIdAdd} buttonName={"test id add "} />
                    <AddButton onClick={handleTestIdRemove} buttonName={"test id remove"} />

                </div>
            </div>
        </>
    )
}

export default GoogleSettingOrganic;