import React from "react";
import "../Css/Apps.css";
import { useEffect } from "react";
import { useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import TableData from "../Components/Table/TableData";
import AddButton from "../Components/Button/AddButton";
import apiServices from "../services/apiServices";
import Sidebar from "../Components/Sidebar/Sidebar";
import Card from "react-bootstrap/Card";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { useLocation, useSearchParams } from "react-router-dom";


const Apps = () => {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [adxData, setAdxData] = useState([]);
  const [tabEvent, setTabEvent] = useState(location.state?.appTab || "develope");
  const [appName, setAppName] = useState("Develop Apps");
  const [consoleName, setConsoleName] = useState([]);
  const [isEditData, setIsEditData] = useState(false);
  const [selectedAppData, setSelectedAppData] = useState("");
  // const [locationData, setLocationData] = useState();
  const [appTabFormData, setAppTabFormData] = useState({
    play_store: "Select console name",
    adx: "",
    app_code: "",
    app_name: "",
    package_name: "",
    // web_url: "",
    icon: "",
    notes: "",
    // admin_url: "",
    // user_url: "",
    status: "develope",
  });
  // console.log("appTabFormData::::", appTabFormData.play_store)
  const [selectedConsoleId, setSelectedConsoleId] = useState("");
  const [appSearchData, setAppsearchData] = useState("");
  const [searchBtn, setSearchBtn] = useState("");

  // console.log("consoleNme:::", selectedConsoleId)


  const handleSearchBtn = () => {
    setSearchBtn(appSearchData);
  };



  const getAllAdxData = async () => {
    const response = await apiServices.getAllAdxData();
    // console.log("responseGetAdx", response);
    setAdxData(response?.data?.getAllAdex);
  };
  useEffect(() => {
    setSearchParams({})
    getAllAdxData();
  }, []);

  const handleSelectTab = (eventKey) => {
    setTabEvent(eventKey);
    if (isEditData) {
      setAppTabFormData({
        play_store: "Select console name",
        adx: "",
        app_code: "",
        app_name: "",
        package_name: "",
        // web_url: "",
        icon: "",
        notes: "",
        status: "develope",
      })

    }
    switch (eventKey) {
      case "develope":
        setAppName("Develop Apps");
        break;
      case "upload":
        setAppName("Upload Apps");
        break;
      case "live":
        setAppName("Live Apps");
        break;
      case "suspended":
        setAppName("Suspended Apps");
        break;
      case "ctro":
        setAppName("CTRO Apps");
        break;

      default:
        // window.location.reload()
        setAppName("Add Apps");

    }
  };
  // console.log("selectedCosoleId::::", selectedConsoleId)
  const handleCreateAddData = async (e) => {
    e.preventDefault();
    const data = {
      addApp: {
        ...appTabFormData,
        upload_date: new Date(),
        date:
          appTabFormData.status == "live"
            ? appTabFormData.date
              ? appTabFormData.date
              : new Date()
            : new Date(),
      },
    };
    // if (appTabFormData.status == "live") {
    //     data.addApp.date = new Date();
    //     appTabFormData.addApp.date = new Date()
    // }

    if (isEditData) {
      const res = await apiServices.updateApp(
        data,
        selectedAppData,
        // locationData,
        selectedConsoleId
      );
      if (res) {
        setTabEvent(appTabFormData.status);
      }
      if (!res) {
        deletenotification("error");
      }
    } else {
      const res = await apiServices.createApp(
        {
          ...appTabFormData,
          upload_date: new Date(),
          date: new Date(),
        },
        // locationData,
        selectedConsoleId
      );
      setAppTabFormData({
        play_store: "Select console name",
        adx: "",
        app_code: "",
        app_name: "",
        package_name: "",
        // web_url: "",
        icon: "",
        notes: "",
        // admin_url: "",
        // user_url: "",
        status: "develope",
      })

      if (res) {
        setTabEvent(appTabFormData.status);
      }
      if (!res) {
        deletenotification("error");
      }
    }
  };
  const deletenotification = () => {
    NotificationManager.error("Package name is already exist");
  };
  // const handleInputChangeLocation = (e) => {
  //   setLocationData(e.target.value);
  // };
  const handleInputChange = (event, isdata) => {
    if (isdata == "selectbox") {
      // console.log("event.target.name::::", event.target.value)
      setSelectedConsoleId(event.target.value?.split(" ")[0]);
    }
    const { name, value } = event.target;
    setAppTabFormData((prevData) => ({
      ...prevData,
      [name]: isdata == "selectbox" ? value?.split(" ")[2] : value,
    }));
  };

  const handleIconImage = async (event) => {
    const imageBaseUrl = "https://app-admin-img.webscal.com/";
    const { name } = event.target;
    const data = event.target.files;
    const imageData = {
      image: data[0],
    };

    // console.log("dataImage+++", data)

    const res = await apiServices.uploadImage({ ...imageData });
    // console.log("ImageData+++++++", res)

    setAppTabFormData((prevData) => ({
      ...prevData,
      [name]: `${imageBaseUrl}${res?.image_name}`,
    }));
  };

  const getConsoleName = async () => {
    const res = await apiServices.getAllConsole("", 100);
    // console.log("res?.data:::", res?.data)
    if (res?.data) {
      setConsoleName(res?.data?.consoleData?.finalArray);
    }
  };
  useEffect(() => {
    getConsoleName();
  }, []);

  // const handleSelectedConsole = (e) => {
  //   console.log("id:::+++++:", e);
  //   // setSelectedConsoleId(id)
  // };

  return (
    <>
      {localStorage.getItem("token") && (
        <div className="col-lg-2">
          <Sidebar />
        </div>
      )}
      <NotificationContainer />
      <div
        className={`${localStorage.getItem("token") ? "col-lg-10" : "col-lg-12"
          }`}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="d-flex justify-content-between mt-3 mb-3">
                <div className="">
                  <h3> {appName} </h3>
                </div>
                <div className="d-flex">
                  {/* <!-- Search form --> */}
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Search"
                    aria-label="Search"
                    onChange={(e) => setAppsearchData(e.target.value)}
                  />
                  <button className="search-btn" onClick={handleSearchBtn}>
                    {" "}
                    Search
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="app-tabs">
                <Tabs
                  id="controlled-tab-example"
                  activeKey={tabEvent}
                  onSelect={handleSelectTab}
                  className="mb-3"
                  justify
                >
                  <Tab
                    eventKey="develope"
                    className="home-tab"
                    title="Develop Apps"
                  >
                    <TableData
                      tableHeadId={"Id"}
                      selectedTabName={tabEvent}
                      tableHeadAppCode={"AppCode"}
                      tableHeadIcon={"Icon"}
                      tableHeadAppName={"App Name"}
                      tableHeadAdx={"Adx"}
                      tableHeadNotes={"Notes"}
                      tableHeadStatus={"Status"}
                      tableHeadAction={"Action"}
                      handleSelectTab={handleSelectTab}
                      // appSelectedData={appSelectedData}
                      setIsEditData={setIsEditData}
                      setAppTabFormData={setAppTabFormData}
                      setSelectedConsoleId={setSelectedConsoleId}
                      setSelectedAppData={setSelectedAppData}
                      searchApp={searchBtn}
                    />
                  </Tab>
                  <Tab eventKey="upload" title="Upload Apps">
                    <TableData
                      tableHeadId={"Id"}
                      selectedTabName={tabEvent}
                      tableHeadAppCode={"AppCode"}
                      tableHeadIcon={"Icon"}
                      tableHeadAppName={"App Name"}
                      tableHeadAdx={"Adx"}
                      tableHeadToday={"Today"}
                      tableHeadYesterday={"Yesterday"}
                      tableHeadAll={"All"}
                      tableHeadNotes={"Notes"}
                      tableHeadStatus={"Date"}
                      tableHeadAction={"Action"}
                      handleSelectTab={handleSelectTab}
                      // appSelectedData={appSelectedData}
                      setIsEditData={setIsEditData}
                      setAppTabFormData={setAppTabFormData}
                      setSelectedAppData={setSelectedAppData}
                      searchApp={searchBtn}
                      setSelectedConsoleId={setSelectedConsoleId}

                    />
                  </Tab>
                  <Tab eventKey="live" title="Live Apps">
                    <div>
                      {/* <Card style={{ width: "18rem" }}>
                        <Card.Img variant="top" src="holder.js/100px180" />
                        <Card.Body>
                          <Card.Title>Card Title</Card.Title>
                          <Card.Text>
                            Some quick example text to build on the card title
                            and make up the bulk of the card's content.
                          </Card.Text>
                        </Card.Body>
                      </Card> */}
                      <TableData
                        tableHeadId={"Id"}
                        selectedTabName={tabEvent}
                        tableHeadAppCode={"AppCode"}
                        tableHeadIcon={"Icon"}
                        tableHeadAppName={"App Name"}
                        tableHeadAdx={"Adx"}
                        tableHeadToday={"Today"}
                        tableHeadYesterday={"Yesterday"}
                        tableHeadAll={"All"}
                        tableHeadNotes={"Notes"}
                        tableHeadStatus={"Live"}
                        tableHeadAction={"Action"}
                        handleSelectTab={handleSelectTab}
                        // appSelectedData={appSelectedData}
                        setIsEditData={setIsEditData}
                        setAppTabFormData={setAppTabFormData}
                        setSelectedAppData={setSelectedAppData}
                        setSelectedConsoleId={setSelectedConsoleId}

                        searchApp={searchBtn}
                      />
                    </div>
                  </Tab>
                  <Tab eventKey="suspended" title="Suspended Apps">
                    <TableData
                      tableHeadId={"Id"}
                      selectedTabName={tabEvent}
                      tableHeadAppCode={"AppCode"}
                      tableHeadIcon={"Icon"}
                      tableHeadAppName={"App Name"}
                      tableHeadAdx={"Adx"}
                      tableHeadToday={"Today"}
                      tableHeadYesterday={"Yesterday"}
                      tableHeadAll={"All"}
                      tableHeadNotes={"Notes"}
                      tableHeadAction={"Action"}
                      handleSelectTab={handleSelectTab}
                      // appSelectedData={appSelectedData}
                      setIsEditData={setIsEditData}
                      setAppTabFormData={setAppTabFormData}
                      setSelectedAppData={setSelectedAppData}
                      setSelectedConsoleId={setSelectedConsoleId}

                      searchApp={searchBtn}
                    />
                  </Tab>
                  <Tab eventKey="ctro" title="CTRO">
                    <TableData
                      tableHeadId={"Id"}
                      selectedTabName={tabEvent}
                      tableHeadAppCode={"AppCode"}
                      tableHeadIcon={"Icon"}
                      tableHeadAppName={"App Name"}
                      tableHeadAdx={"Adx"}
                      tableHeadToday={"Today"}
                      tableHeadYesterday={"Yesterday"}
                      tableHeadAll={"All"}
                      tableHeadNotes={"Notes"}
                      tableHeadStatus={"Live"}
                      tableHeadAction={"Action"}
                      handleSelectTab={handleSelectTab}
                      // appSelectedData={appSelectedData}
                      setIsEditData={setIsEditData}
                      setSelectedConsoleId={setSelectedConsoleId}

                      setAppTabFormData={setAppTabFormData}
                      setSelectedAppData={setSelectedAppData}
                      searchApp={searchBtn}
                    />
                  </Tab>
                  <Tab eventKey="add-apps" title="Add Apps">
                    <form className="add-app-select-tab">
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label htmlFor="exampleFormControlSelect1">
                              Playstore
                            </label>
                            <select
                              className="form-select"
                              name="play_store"
                              // value={iul}
                              onChange={(e) =>
                                handleInputChange(e, "selectbox")
                              }
                              aria-label="Default select example"
                            // value={appTabFormData.play_store}
                            >
                              <option selected={appTabFormData.play_store == "Select console name"}>Select console name</option>
                              {consoleName?.map((item, index) => (
                                <option
                                  key={index}
                                  // name={item.name}
                                  value={item._id + " " + "name" + " " + item.name}
                                  selected={appTabFormData.play_store == item.name}
                                >
                                  {item.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label htmlFor="exampleFormControlSelect1">
                              Adx
                            </label>
                            <select
                              className="form-select"
                              name="adx"
                              value={appTabFormData.adx}
                              onChange={handleInputChange}
                              aria-label="Default select example"
                            >
                              <option selected>Open this select menu</option>
                              {adxData?.map((item, index) => (
                                <option
                                  key={index}
                                  value={item.name}
                                  selected={appTabFormData.adx == ""}
                                >
                                  {item.name}
                                </option>
                              ))}

                              {/* <option value="1">One</option>
                                                            <option value="2">Two</option>
                                                            <option value="3">Three</option> */}
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label htmlFor="app_code">App Code</label>
                            <input
                              type="text"
                              name="app_code"
                              value={appTabFormData.app_code}
                              className="form-control"
                              id="appCode"
                              placeholder=""
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label htmlFor="appname">App Name</label>
                            <input
                              type="text"
                              name="app_name"
                              value={appTabFormData.app_name}
                              className="form-control"
                              id="appname"
                              placeholder=""
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label htmlFor="package">Package Name</label>
                            <input
                              type="text"
                              name="package_name"
                              value={appTabFormData.package_name}
                              className="form-control"
                              id="package"
                              placeholder=""
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                        {/* <div className="col-lg-6">
                          <div className="form-group">
                            <label htmlFor="weburl">Web URL</label>
                            <input
                              type="text"
                              name="web_url"
                              value={appTabFormData.web_url}
                              className="form-control"
                              id="weburl"
                              placeholder=""
                              onChange={handleInputChange}
                            />
                          </div>
                        </div> */}
                      </div>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="mt-1">
                            <label htmlFor="formFile" className="form-label">
                              Icon
                            </label>
                            <input
                              className="form-control"
                              type="file"
                              id="formFile"
                              name="icon"
                              onChange={handleIconImage}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label htmlFor="notes">Notes</label>
                            <input
                              type="text"
                              name="notes"
                              value={appTabFormData.notes}
                              className="form-control"
                              id="notes"
                              placeholder=""
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        {/* <div className="col-lg-6">
                          <div className="form-group">
                            <label htmlFor="adminurl">Admin URL</label>
                            <input
                              type="text"
                              name="admin_url"
                              value={appTabFormData.admin_url}
                              className="form-control"
                              id="adminurl"
                              placeholder=""
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label htmlFor="user_url">User URL</label>
                            <input
                              type="text"
                              name="user_url"
                              value={appTabFormData.user_url}
                              className="form-control"
                              id="user_url"
                              placeholder=""
                              onChange={handleInputChange}
                            />
                          </div>
                        </div> */}
                        {/* <div className="col-lg-6">
                          <div className="form-group">
                            <label htmlFor="user_url">Location</label>
                            <input
                              type="text"
                              name="location"
                              value={locationData}
                              className="form-control"
                              id="location"
                              placeholder=""
                              onChange={handleInputChangeLocation}
                            />
                          </div>
                        </div> */}
                      </div>
                      <label className="label-status"> Status</label>
                      <div className="add-app-radio-btn-group d-flex">
                        <div className="form-check radio-btn-all">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="status"
                            id="develope"
                            value="develope"
                            checked={appTabFormData.status == "develope"}
                            onChange={handleInputChange}
                          // defaultChecked
                          />
                          <label
                            className="form-check-label"
                            htmlFor="develope"
                          >
                            Develope
                          </label>
                        </div>
                        <div className="form-check radio-btn-all">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="status"
                            id="upload"
                            value="upload"
                            checked={appTabFormData.status == "upload"}
                            onChange={handleInputChange}
                          />
                          <label className="form-check-label" htmlFor="upload">
                            Upload
                          </label>
                        </div>
                        <div className="form-check radio-btn-all">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="status"
                            id="live"
                            value="live"
                            checked={appTabFormData.status == "live"}
                            onChange={handleInputChange}
                          />
                          <label className="form-check-label" htmlFor="live">
                            Live
                          </label>
                        </div>
                        <div className="form-check radio-btn-all">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="status"
                            id="suspended"
                            value="suspended"
                            checked={appTabFormData.status == "suspended"}
                            onChange={handleInputChange}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="suspended"
                          >
                            Suspended
                          </label>
                        </div>
                        {/* <label>Update Date</label> */}
                      </div>
                      {/* <input type="date" /> */}
                      <AddButton
                        buttonName={"Add"}
                        onClick={handleCreateAddData}
                        isEditButton={isEditData}
                      />
                    </form>
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className='container'>
                <div className='row'>
                    <div className='col-lg-12'>

                    </div>
                </div>
            </div> */}
    </>
  );
};

export default Apps;
