import React from 'react';
import "./AddButton.css"

const AddButton = (props) => {
    // console.log("proos:::", props)
    return (
        <>
            <div className={`all-add-button ${props.buttonStyle}`} >
                <button className='add-button' onClick={props.onClick} disabled={props.disabled}>{props.isEditButton ? "Update" : props.buttonName}</button>
            </div>


        </>
    )
}

export default AddButton