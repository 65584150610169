import React, { useEffect, useState } from 'react';
import "./Header.css";
import { useNavigate } from 'react-router';


const Header = () => {
    const [appNameStore, setAppNameStore] = useState();
    const navigate = useNavigate();

    const handleOrganicData = () => {
        window.open("/jsondata", "_blank");
        // navigate("/jsonData")
    }
    const handleMarketingData = () => {
        window.open("/jsondata-m", "_blank");
        // navigate("/jsondata-m")
    }

    useEffect(() => {
        if (localStorage.getItem("id")) {
            if (localStorage.getItem("appAllData")) {
                const data = JSON.parse(localStorage.getItem("appAllData"))
                console.log("appAllData++++++++", data)
                setAppNameStore(data)
            }
        }
    }, [])

    return (
        <>
            <div className='row'>
                <div className='col-lg-12'>
                    <div className="main-header">
                        <div className='branding-logo d-flex mb-2'>

                            <div>
                                <img width="50" height="60" src={appNameStore?.addApp?.icon} />
                            </div>
                            <div>
                                <h3 style={{ padding: "0px 10px" }}>
                                    {appNameStore?.addApp?.app_name}
                                </h3>
                                <h5 style={{ padding: "0px 10px" }}>{appNameStore?.addApp?.package_name}</h5>
                            </div>
                        </div>
                        <div style={{ padding: "20px" }}>
                            <button onClick={handleOrganicData} type="button" className="btn btn-primary">Organic</button>
                            <button onClick={handleMarketingData} type="button" className="btn btn-secondary">Marketing</button>
                        </div>


                    </div>
                </div>
            </div>

        </>
    )
}

export default Header;